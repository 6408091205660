import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { ColumnWithSort } from "components/atoms";
import Moment from "react-moment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import { Confirmation } from "components/molecules";
import { blockUnblockDeleteUser } from "store/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { showAction } from "utils/helper";

const TableList = (props) => {
  const {
    listData,
    filter,
    toggleSortOrder,
    blockUnblockDeleteUser,
    handleFilterSubmit,
    alPermissions,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData.result : [];
  const [open, setOpen] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {},
  });

  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "activate") {
      obj = {
        ...obj,
        title: "Activate user",
        description: `Are you sure you want to activate ${row.name} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 1,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete user",
        description: `Are you sure you want to delete ${row.name} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 3,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Block user",
        description: `Are you sure you want to block ${row.name} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 2,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        <figure>
          <FindInPageIcon />
        </figure>
        <h3>
          {listData?.result === undefined ? "Loading ..." : "No Data Found"}
        </h3>
      </div>
    );
  }
  return (
    <div className="table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <ColumnWithSort
                  title="Name"
                  sortBy={filter?.sortBy}
                  currentKey="name"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Email ID</TableCell>
              <TableCell>
                <ColumnWithSort
                  title="Added On"
                  sortBy={filter?.sortBy}
                  currentKey="addedOn"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Status</TableCell>
              {showAction(alPermissions, 2) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link to={`/fans/${row._id}`}>{row.name}</Link>
                </TableCell>
                <TableCell>
                  {row?.countryCode !== "NA" ? row?.countryCode : ""}{" "}
                  {row.phoneNo}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY">{row.addedOn}</Moment>
                </TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{"N/A"}</TableCell>
                <TableCell
                  className={
                    row.status === 1 ? "status-active" : "status-block"
                  }
                >
                  {row.status === 1 ? "Active" : "Blocked"}
                </TableCell>
                {showAction(alPermissions, 2) && (
                  <TableCell>
                    <div className="action-wrap">
                      {row.status === 1 ? (
                        <Tooltip title="Block">
                          <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="span"
                            onClick={() =>
                              handleClickOpen({ type: "de-activate", row })
                            }
                          >
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Activate">
                          <IconButton
                            onClick={() =>
                              handleClickOpen({ type: "activate", row })
                            }
                          >
                            <CheckCircleOutlineIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

TableList.propTypes = {
  listData: PropTypes.any,
  filter: PropTypes.object,
  toggleSortOrder: PropTypes.func,
  blockUnblockDeleteUser: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  blockUnblockDeleteUser: (data) => dispatch(blockUnblockDeleteUser(data)),
});
export default connect(mapState, mapDispatch)(TableList);
