import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  ReportPostTab,
  ReportUserTab,
} from "components/molecules";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation, useHistory } from "react-router-dom";
import { showSection } from "utils/helper";
import { connect } from "react-redux";

const ReportedContentList = (props) => {
  const { alPermissions } = props;

  const history = useHistory();
  const { search } = useLocation();
  const qSearch = new URLSearchParams(search);
  const [tabvalue, setValue] = React.useState(qSearch.get("type") || "1");

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 7))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(`/reported-content?type=${newValue}`);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section static-content">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Report Management</h1>
              </div>
            </div>
            <div className="tabs-section">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabvalue}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="Report Management"
                    >
                      <Tab label="Users" value="1" />
                      <Tab label="Posts" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <ReportUserTab />
                  </TabPanel>
                  <TabPanel value="2">
                    <ReportPostTab />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});

export default connect(mapState, null)(ReportedContentList);
