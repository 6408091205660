import * as React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { editFaq, getFaq } from "store/actions";
import { Loader } from "components/atoms";
import { showSection } from "utils/helper";

const FaqEdit = (props) => {
  const { getFaq, alPermissions, showLoader, editFaq } = props;
  const history = useHistory();
  const { id } = useParams();

  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 4))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    getFaq({ id }).then((resp) => {
      if (resp?.faq) {
        setQuestion(resp.faq.question);
        setAnswer(resp.faq.answer);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      faqQuestion: {
        question: question,
        answer: answer,
      },
    };
    editFaq({ data, id }).then((resp) => {
      if (resp) {
        setQuestion("");
        setAnswer("");
        history.goBack();
      }
    });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="static-content-header">
              <div className="title-left">
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h1 className="title">Edit</h1>
              </div>
              <div className="btn-group">
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </div>
            <div className="text-editor">
              <h2 className="text-editor-heading">Question</h2>
              <ReactQuill
                theme="snow"
                onChange={setQuestion}
                value={question}
              />
            </div>
            <div className="text-editor">
              <h2 className="text-editor-heading">Answer</h2>
              <ReactQuill theme="snow" onChange={setAnswer} value={answer} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  editFaq: (data) => dispatch(editFaq(data)),
  getFaq: (data) => dispatch(getFaq(data)),
});

export default connect(mapState, mapDispatch)(FaqEdit);
