import React from "react";
import "./index.css";
import FooterPattern from "assets/images/footer-pattern.png";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { getAllStaticContent } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { Loader } from "components/atoms";

const TermsToUse = (props) => {
  const { getAllStaticContent, showLoader } = props;
  const [listData, setListData] = React.useState(null);

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { type: 5 };
    const params = convertObjToStringParams(apiData);
    getAllStaticContent({ params }).then((resp) => {
      setListData(resp);
    });
  };
  return (
    <div className="policys-wrapper terms">
      {showLoader && <Loader />}
      <div className="policys-inner">
        {listData?.content && parse(listData?.content)}
      </div>
      <div className="footer-img">
        <figure>
          <img src={FooterPattern} alt="" />
        </figure>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getAllStaticContent: (data) => dispatch(getAllStaticContent(data)),
});

export default connect(mapState, mapDispatch)(TermsToUse);
