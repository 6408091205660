import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  NotificationDetailTableList,
  PaginationSection,
} from "components/molecules";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TextField from "@mui/material/TextField";
import uploadImage from "assets/images/user_ic.svg";
import Popover from "@mui/material/Popover";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { FilterSubmit, FilterReset, Loader } from "components/atoms";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import {
  convertObjToStringParams,
  showAction,
  showSection,
  getReceiverType,
} from "utils/helper";
import {
  getNotificationDetail,
  getNotificationDetail_,
  getSelectedUsers,
  sendNotification,
} from "store/actions";
import { connect } from "react-redux";

const NotificationDetail = (props) => {
  let history = useHistory();
  const { id: notificationId } = useParams();
  const {
    getNotificationDetail,
    showLoader,
    detail,
    alPermissions,
    getNotificationDetail_,
    getSelectedUsers,
    sendNotification,
  } = props;

  const [value, setValue] = React.useState([null, null]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    from: "",
    to: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "addedOn", val: -1 },
    status: "",
    error: "",
  });
  const [listData, setListData] = React.useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 8))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchDetail();
    return () => {
      getNotificationDetail_(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = {
      id: notificationId,
    };
    const params = convertObjToStringParams(apiData);
    getNotificationDetail({ params }).then(() => setCounter((pc) => pc + 1));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Debouncing For Search
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;

    setFilter(newFilter);
  };

  const getApiData = () => {
    let apiData = {
      id: notificationId,
      page: filter.pageNo,
      limit: filter.pageLimit,
      sortKey: filter.sortBy.key,
      sortOrder: filter.sortBy.val,
    };
    if (value[0]) apiData.from = value[0];
    if (value[1]) apiData.to = value[1];
    if (filter.search) apiData.search = filter.search;
    if (filter.status) apiData.status = filter.status;
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    setAnchorEl(null);
    if (detail?.receiverType && parseInt(detail?.receiverType) === 8) {
      getSelectedUsers({ params }).then((apiData) => {
        setListData(apiData);
      });
    }
  };

  const handleFilterReset = () => {
    const newFilter = { ...filter };
    newFilter.from = "";
    newFilter.to = "";
    newFilter.status = "";
    newFilter.error = "";
    setValue([null, null]);
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const showDefaultImage = (e) => {
    e.target.src = uploadImage;
  };

  const handleSendNotification = () => {
    const apiData = {
      id: notificationId,
    };
    sendNotification(apiData).then((resp) => {
      if (resp) {
        history.push("/notifications");
      }
    });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section add-athlete">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h1 className="title">Notification Detail</h1>
              </div>
              {showAction(alPermissions, 8) && (
                <div className="breadcrumb-right">
                  <Button variant="contained" onClick={handleSendNotification}>
                    Send
                  </Button>
                </div>
              )}
            </div>
            <div className="add-notification-wrap">
              <div className="form-row">
                <div className="col-half">
                  <div className="notification-text detail">
                    <label className="form-label">Title*</label>
                    <p className="detail-title">{detail?.title}</p>
                  </div>
                </div>
                <div className="col-half">
                  <div className="notification-text detail">
                    <label className="form-label">URL*</label>
                    <p className="detail-title">{detail?.url}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="notification-info">
              <div className="notification-description detail">
                <label className="form-label">Notification Image</label>
                <div
                  className="upload-image"
                  hidden={detail?.image ? false : true}
                >
                  <figure>
                    <img
                      src={detail?.image}
                      alt="User Icon"
                      onError={showDefaultImage}
                    />
                  </figure>
                </div>
              </div>
            </div>
            {/* <div className="form-row" hidden>
              <div className="col-half">
                <div className="notification-text detail" hidden>
                  <label className="form-label">URL*</label>
                  <div className="description-info">
                    <p className="detail-title">
                      Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                      Ipsum Lorem Ipsum
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="form-row">
              <div className="col-half">
                <div className="notification-text detail">
                  <label className="form-label">Recipient*</label>
                  {detail?.receiverType && (
                    <div className="btn-row">
                      {getReceiverType(parseInt(detail?.receiverType))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {detail?.receiverType && parseInt(detail?.receiverType) === 8 && (
              <>
                <div className="search-filter-wrap">
                  <div className="serach-wrap">
                    <TextField
                      label="Search"
                      variant="outlined"
                      placeholder="Search by name, email and mobile number"
                      onChange={handleSearch}
                      value={filter?.search}
                    />
                  </div>
                  <div className="filter-btn">
                    <Tooltip title="Filter">
                      <IconButton
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        color="primary"
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <div className="filterPopup">
                        <label className="form-label">Added Date</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateRangePicker
                            startText="From"
                            endText="To"
                            value={value}
                            open={datePickerOpen}
                            onChange={(newValue) => {
                              setValue(newValue);
                              if (
                                newValue[0] !== null &&
                                newValue[1] !== null
                              ) {
                                setDatePickerOpen(false);
                              }
                            }}
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField
                                  onClick={() => {
                                    setDatePickerOpen(true);
                                  }}
                                  {...startProps}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField
                                  onClick={() => {
                                    setDatePickerOpen(true);
                                  }}
                                  {...endProps}
                                />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                        <div className="filter-text">
                          <label className="form-label">Status</label>
                          <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                              label="Status"
                              placeholder="Status"
                              value={filter?.status}
                              onChange={handleChangeFilter("status")}
                            >
                              <MenuItem value={1}>Active</MenuItem>
                              <MenuItem value={2}>Blocked</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="btn-wrap">
                          <FilterReset
                            name="Reset"
                            onClickButton={handleFilterReset}
                          />
                          <FilterSubmit
                            name="Apply Filter"
                            onClickButton={handleFilterSubmit}
                          />
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
                <NotificationDetailTableList
                  listData={listData}
                  filter={filter}
                  toggleSortOrder={toggleSortOrder}
                  handleFilterSubmit={handleFilterSubmit}
                  alPermissions={alPermissions}
                />
                {listData?.result?.length > 0 && (
                  <PaginationSection
                    filter={filter}
                    listData={listData}
                    handleChangePage={handleChangePage}
                    handleChangePageLimit={handleChangePageLimit}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.notificationInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getNotificationDetail: (data) => dispatch(getNotificationDetail(data)),
  getNotificationDetail_: (data) => dispatch(getNotificationDetail_(data)),
  sendNotification: (data) => dispatch(sendNotification(data)),
  getSelectedUsers: (data) => dispatch(getSelectedUsers(data)),
});
export default connect(mapState, mapDispatch)(NotificationDetail);
