import * as React from "react";
import { PaginationSection, UsersTableList } from "components/molecules";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { getAllReports } from "store/actions";
import { Loader } from "components/atoms";
import { convertObjToStringParams } from "utils/helper";

const ReportUserTab = (props) => {
  const { getAllReports, showLoader } = props;
  const [listData, setListData] = React.useState(null);

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "createdAt", val: -1 },
    error: "",
  });

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      sortKey: filter.sortBy.key,
      sortOrder: filter.sortBy.val,
    };
    if (filter.search) apiData.search = filter.search;
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getAllReports({ params, type: "user" }).then((apiData) => {
      setListData(apiData);
    });
  };

  //Debouncing For Search
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="user-tab-wrap">
      {showLoader && <Loader />}
      <div className="search-filter-wrap">
        <div className="serach-wrap">
          <TextField
            label="Search"
            variant="outlined"
            placeholder="Search by reported user"
            onChange={handleSearch}
            value={filter?.search}
          />
        </div>
      </div>
      <UsersTableList
        listData={listData}
        filter={filter}
        toggleSortOrder={toggleSortOrder}
        handleFilterSubmit={handleFilterSubmit}
      />
      {listData?.result?.length > 0 && (
        <PaginationSection
          filter={filter}
          listData={listData}
          handleChangePage={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
        />
      )}
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getAllReports: (data) => dispatch(getAllReports(data)),
});
export default connect(mapState, mapDispatch)(ReportUserTab);
