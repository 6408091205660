import React from "react";
import "./index.css";
import Webaboutus from "assets/images/web_about_us.png";
import Mobileaboutus from "assets/images/app_about_us.png";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { getAllStaticContent } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { Loader } from "components/atoms";

const AboutUss = (props) => {
  const { getAllStaticContent, showLoader } = props;
  const [listData, setListData] = React.useState(null);

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { type: 4 };
    const params = convertObjToStringParams(apiData);
    getAllStaticContent({ params }).then((resp) => {
      setListData(resp);
    });
  };
  return (
    <div className="policys-wrapper-aboutus">
      {showLoader && <Loader />}
      <div className="policys-inner">
        {listData?.content && parse(listData?.content)}
      </div>
      <div className="aboutus-footer-img d-none">
        <figure>
          <img src={Webaboutus} alt="Image" />
        </figure>
      </div>
      <div className="aboutus-footer-img-mb">
        <figure>
          <img src={Mobileaboutus} alt="Image" />
        </figure>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getAllStaticContent: (data) => dispatch(getAllStaticContent(data)),
});
export default connect(mapState, mapDispatch)(AboutUss);
