import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function createData(
  name,
  mobileNumber,
  emailId,
  addedOn,
  username,
  subscription,
  status,
  action
) {
  return {
    name,
    mobileNumber,
    emailId,
    addedOn,
    username,
    subscription,
    status,
    action,
  };
}
const rows = [
  createData(
    "Martin James",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "Ashley123",
    "NA",
    "Active",
    ""
  ),
  createData(
    "Lincoln Adams",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "Green321",
    "Monthly",
    "Active",
    ""
  ),
  createData(
    "Lisa Haydon",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "Alex234",
    "Yearly",
    "Active",
    ""
  ),
  createData(
    "Samantha Grey",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "Alex123",
    "Monthly",
    "Active",
    ""
  ),
  createData(
    "Amanda James",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "Hales123",
    "NA",
    "Blocked",
    ""
  ),
  createData(
    "Anna Keys",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "David123",
    "Yearly",
    "Active",
    ""
  ),
  createData(
    "Lisa Haydon",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "Bob123",
    "Yearly",
    "Blocked",
    ""
  ),
  createData(
    "Lisa Haydon",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "Marley123",
    "Yearly",
    "Active",
    ""
  ),
  createData(
    "Lisa Haydon",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "Jack345",
    "Yearly",
    "Active",
    ""
  ),
  createData(
    "Lisa Haydon",
    "+16 675356765",
    "user@appinventiv.com",
    "22Oct,2021",
    "Daniels123",
    "Monthly",
    "Active",
    ""
  ),
];

const AthletesFollowingsTableList = (props) => {
  return (
    <div className="table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Email ID</TableCell>
              <TableCell>Added on</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.mobileNumber}</TableCell>
                <TableCell>{row.emailId}</TableCell>
                <TableCell>{row.addedOn}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{"N/A"}</TableCell>
                <TableCell
                  className={
                    row.status === 1 ? "status-active" : "status-block"
                  }
                >
                  {row.status}
                </TableCell>
                <TableCell>
                  <div className="action-wrap">
                    <Tooltip title="Unblock">
                      <IconButton>
                        <CheckCircleOutlineIcon color="success" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Block">
                      <IconButton
                        color="error"
                        aria-label="upload picture"
                        component="span"
                      >
                        <BlockIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        color="error"
                        aria-label="upload picture"
                        component="span"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AthletesFollowingsTableList;
