import { GET_VIDEO_DETAIL } from "store/types";

const initialState = {
  current: null,
};

const videoFeedReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEO_DETAIL:
      state.current = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default videoFeedReducer;
