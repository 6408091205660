import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(transaction, subscription) {
  return {
    transaction,
    subscription,
  };
}

const rows = [
  createData("29 September 2019, 7:00 pm", "Monthly"),
  createData("29 September 2019, 7:00 pm", "Yearly"),
  createData("29 September 2019, 7:00 pm", "Monthly"),
  createData("29 September 2019, 7:00 pm", "Monthly"),
  createData("29 September 2019, 7:00 pm", "Yearly"),
  createData("29 September 2019, 7:00 pm", "Yearly"),
  createData("29 September 2019, 7:00 pm", "Monthly"),
  createData("29 September 2019, 7:00 pm", "Monthly"),
  createData("29 September 2019, 7:00 pm", "Monthly"),
  createData("29 September 2019, 7:00 pm", "Monthly"),
];

const FanSubscriptionTableList = () => {
  return (
    <div className="table-wrapper subscription">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Transaction Date & Time</TableCell>
              <TableCell>Subscription</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.transaction}</TableCell>
                <TableCell>{"N/A"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FanSubscriptionTableList;
