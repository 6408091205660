import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

function createData(user, ranked) {
  return { user, ranked };
}

const rows = [
  createData("Ashley green", 4.5),
  createData("Mark", 3.6),
  createData("Drake", 4.8),
  createData("Justin", 4.5),
  createData("Tom latham", 4.5),
  createData("Dominic Sibley", 4.5),
  createData("Paul walker", 4.5),
  createData("Allen james", 4.5),
  createData("Dominic Sibley", 4.5),
  createData("Allen james", 4.5),
];

const AthletesRankedTableList = (props) => {
  return (
    <div className="rank-tab table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link>{row.user}</Link>
                </TableCell>
                <TableCell>{row.ranked}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AthletesRankedTableList;
