import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  ProfileInfo,
  PasswordInfo,
} from "components/molecules";
import { Loader } from "components/atoms";
import { connect } from "react-redux";
import { changeMyPassword } from "store/actions";

const AdminProfileManagement = (props) => {
  const { changeMyPassword, showLoader, detail } = props;
  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">My Profile</h1>
              </div>
            </div>
            <ProfileInfo info={detail} />
            <PasswordInfo changeMyPassword={changeMyPassword} />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state?.authInfo?.auth,
});
const mapDispatch = (dispatch) => ({
  changeMyPassword: (data) => dispatch(changeMyPassword(data)),
});

export default connect(mapState, mapDispatch)(AdminProfileManagement);
