export const convertObjToStringParams = (obj) => {
  const mappedKeys = Object.keys(obj).map((k) => {
    return `${k}=${obj[k]}`; 
  });

  return mappedKeys.join("&");
};

// Note:- Don't change these array
export const modulesArr = [
  {
    name: "Athlete Management",
    value: 1,
    id: 1,
  },
  {
    name: "Fan Management",
    value: 2,
    id: 2,
  },
  {
    name: "Sub-Admin Management",
    value: 3,
    id: 3,
  },
  {
    name: "Static Content Management",
    value: 4,
    id: 4,
  },
  // {
  //   name: "Basic",
  //   value: 5,
  //   id: 5,
  // },
  {
    name: "Video Feed Management",
    value: 6,
    id: 6,
  },
  {
    name: "Report Management",
    value: 7,
    id: 7,
  },
  {
    name: "Notification Management",
    value: 8,
    id: 8,
  },
  {
    name: "Earning Management",
    value: 9,
    id: 9,
  },
  {
    name: "Sports Management",
    value: 10,
    id: 10,
  },
  {
    name: "Support Management",
    value: 11,
    id: 11,
  },
];

export const permissionsArr = [
  {
    name: "Viewer", //Read
    value: 1,
  },
  {
    name: "Modifier", //Read/Write
    value: 2,
  },
];

export const nestedSections = [
  {
    name: "User Management",
    value: 111,
  },
];

export const showSection = (allowedPerm = [], sectionVal = 0) => {
  let flag = false;
  const superUser = allowedPerm.find((alp) => {
    if (alp.name === 5 && alp.value === 2) return true;
    return false;
  });
  if (superUser) {
    flag = true;
  } else {
    const found = allowedPerm.find((alp) => {
      if (sectionVal === 111) {
        // User management
        if (alp.name === 1 || alp.name === 2) return true;
      }
      if (alp.name === sectionVal) return true;
      return false;
    });
    if (found) flag = true;
  }
  return flag;
};

export const showAction = (allowedPerm = [], sectionVal = 0) => {
  let flag = false;
  const superUser = allowedPerm.find((alp) => {
    if (alp.name === 5 && alp.value === 2) return true;
    return false;
  });
  if (superUser) {
    flag = true;
  } else {
    const found = allowedPerm.find((alp) => {
      if (alp.name === sectionVal && alp.value === 2) return true;
      return false;
    });
    if (found) flag = true;
  }
  return flag;
};

const subOrdinate = {
  Position: 1,
  Stance: 2,
  Style: 3, // Discipline
  "N/A": 4,
};

const subscription = {
  monthly: 1,
  yearly: 2,
  "N/A": 3,
  free: 4,
};


export const getSubOrdinate = (type = 1) => {
  let keys = Object.keys(subOrdinate);
  let found = keys.filter((it) => subOrdinate[it] == type);
  return found[0];
};

export const notificationsArray = [
  {
    name: "All Users",
    value: 1,
  },
  {
    name: "Fan",
    value: 2,
  },
  {
    name: "Athlete",
    value: 3,
  },
  {
    name: "Fan Basic",
    value: 4,
  },
  {
    name: "Athlete Basic",
    value: 5,
  },
  {
    name: "iOS",
    value: 6,
  },
  {
    name: "Android",
    value: 7,
  },
  {
    name: "Selected User",
    value: 8,
  },
  {
    name: "Other",
    value: 9,
  },
];

export const EarningDaysManagement = [
  {
    name: "Today",
    value: 1,
  },
  {
    name: "Last 7 Days",
    value: 2,
  },
  {
    name: "Last 30 Days",
    value: 3,
  },
  {
    name: "All",
    value: 4,
  },
];

export const getReceiverType = (type = 1) => {
  let found = notificationsArray.filter((it) => it.value === type);
  return found[0].name;
};

// always 2 decimal values
export const roundOff = (val) => {
  return (Math.round(val * 100) / 100).toFixed(2);
};
