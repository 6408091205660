import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Search from "assets/images/Vector.png";
import "./index.css";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { getAllStaticContent } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { Loader } from "components/atoms";
import { Close } from "@mui/icons-material";

const Faqs = (props) => {
  const { getAllStaticContent, showLoader } = props;
  const [listData, setListData] = React.useState([]);

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "addedOn", val: -1 },
    error: "",
  });

  React.useEffect(() => {
    fetchDetail();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getApiData = () => {
    let apiData = {
      type: 2,
    };
    if (filter.search) apiData.search = filter.search;
    return apiData;
  };

  const fetchDetail = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getAllStaticContent({ params }).then((resp) => {
      setListData(resp);
    });
  };

  //Debouncing For Search
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  const handleClearSearch = () => {
    const newFilter = { ...filter };
    newFilter["search"] = "";
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="policys-wrapper blacktheme">
      <div className="policys-inner">
        <div className="search-wrap-faq">
          <TextField
            variant="outlined"
            placeholder="Search your queries"
            onChange={handleSearch}
            value={filter?.search}
          />
          {filter?.search.length === 0 ? (
            <div className="search-icon">
              <img src={Search} alt="Search your queries" />
            </div>
          ) : (
            <Close className="search-icon" onClick={handleClearSearch} />
          )}
        </div>
        <div>
          {showLoader && <Loader relative />}
          <div className="accordian-section">
            {/* <div className="accordian-title">
              <h3 className="title">Account profile</h3>
            </div> */}
            {listData &&
              listData.map((faq, i) => {
                return (
                  <Accordion key={faq._id + i}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <h3>
                          {faq.faqQuestions.question &&
                            parse(faq.faqQuestions.question)}
                        </h3>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {/* <h3 className="blue">
                        {faq.question && parse(faq.question)}
                      </h3> */}
                        {faq.faqQuestions.answer &&
                          parse(faq.faqQuestions.answer)}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getAllStaticContent: (data) => dispatch(getAllStaticContent(data)),
});
export default connect(mapState, mapDispatch)(Faqs);
