import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, emailid, username, status) {
  return {
    name,
    emailid,
    username,
    status,
  };
}

const rows = [
  createData("Martin James", "user@appinventiv.com", "Ashley123", "Active"),
  createData("Lincoln Adams", "user@appinventiv.com", "Green321", "Active"),
  createData("Lisa Haydon", "user@appinventiv.com", "Alex234", "Active"),
  createData("Samantha Grey", "user@appinventiv.com", "Alex123", "Active"),
  createData("Amanda James", "user@appinventiv.com", "Hales123", "Blocked"),
  createData("Anna Keys", "user@appinventiv.com", "David123", "Active"),
  createData("Lisa Haydon", "user@appinventiv.com", "Bob123", "Blocked"),
  createData("Lisa Haydon", "user@appinventiv.com", "Marley123", "Active"),
  createData("Lisa Haydon", "user@appinventiv.com", "Jack345", "Active"),
  createData("Lisa Haydon", "user@appinventiv.com", "Daniels123", "Active"),
];

const FanTaggedTableList = (props) => {
  return (
    <div className="tagged-user table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.emailid}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell
                  className={
                    row.status === 1 ? "status-active" : "status-block"
                  }
                >
                  {row.status === 1 ? "Active" : "Inactive"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FanTaggedTableList;
