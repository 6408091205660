import { GET_SPORT_DETAIL, SET_INITIAL, SET_EDIT_POSITION } from "store/types";

const initialState = {
  current: null,
  editPosition: null,
};

const fanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPORT_DETAIL:
      state.current = action.payload;
      return { ...state };

    case SET_INITIAL:
      state.current = null;
      return { ...state };

    case SET_EDIT_POSITION:
      state.editPosition = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default fanReducer;
