import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";

const Loader = (props) => {
  const { relative } = props;

  return (
    <div
      className={`loader-wrap ${relative ? "loader-relative" : "loader-fixed"}`}
    >
      <div className="loader-inner">
        <CircularProgress className="white-color" />
      </div>
    </div>
  );
};

export default Loader;
