import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  LeftSidebar,
  HeaderSection,
  AthletesSubscriptionTableList,
} from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./index.css";

const AthletesSubscription = (props) => {
  const history = useHistory();

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section athlete-subscription">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="title-left">
                <ArrowBackIosNewIcon onClick={() => history.go(-2)} />
                <h1 className="section-title">Athlete Details</h1>
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h2 className="section-sub-title">Subscription History</h2>
              </div>
            </div>
            <AthletesSubscriptionTableList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AthletesSubscription;
