import PropTypes from "prop-types";
import Button from "@mui/material/Button";

const FilterReset = (props) => {
  const { name, customClass, onClickButton } = props;
  return (
    <Button
      variant="outlined"
      color="error"
      className={customClass}
      onClick={onClickButton}
    >
      {name}
    </Button>
  );
};

FilterReset.propTypes = {
  name: PropTypes.string,
  customClass: PropTypes.string,
  onClickButton: PropTypes.func,
};

export default FilterReset;
