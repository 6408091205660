import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";

const PaginationSection = (props) => {
  const { filter, handleChangePageLimit, listData, handleChangePage } = props;
  const [pageLimit, setPageLimit] = React.useState(filter?.pageLimit || 10);
  const totalRecords = listData?.total || 0;
  const totalPages = Math.ceil(totalRecords / pageLimit) || 1;
  const lowerLimit = pageLimit * (listData?.page - 1) + 1;
  const upperLimit =
    listData?.next && listData?.result?.length === pageLimit
      ? listData.page * pageLimit
      : lowerLimit + (listData?.result?.length - 1);

  const handleChangeLimit = (event) => {
    setPageLimit(event.target.value);
    handleChangePageLimit(event.target.value);
  };
  return (
    <div className="pagination-wrap">
      <div className="pagination">
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            color="primary"
            page={listData?.page || 1}
            onChange={(e, val) => {
              handleChangePage(val);
            }}
          />
        </Stack>
      </div>
      <div className="filterWrap">
        <div className="select-dropdown">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Show</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pageLimit}
              label="Page Limit"
              onChange={handleChangeLimit}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="countWrap">
          <p>
            {lowerLimit || 0}-<b>{upperLimit || 0}</b> of <b>{totalRecords}</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaginationSection;
