import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  SupportTableList,
  PaginationSection,
} from "components/molecules";
import TextField from "@mui/material/TextField";
import { Loader } from "components/atoms";
import { getAllSupport } from "store/actions";
import { convertObjToStringParams, showSection } from "utils/helper";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const SupportList = (props) => {
  const { getAllSupport, showLoader, alPermissions } = props;
  const [listData, setListData] = React.useState(null);

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "createdAt", val: -1 },
    error: "",
  });
  const history = useHistory();

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 11))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      // sortKey: filter.sortBy.key,
      // sortOrder: filter.sortBy.val,
    };
    if (filter.search) apiData.search = filter.search;
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error) return false;
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);

    getAllSupport({ params }).then((resp) => {
      setListData(resp);
    });
  };

  //Debouncing For Search
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Support Management</h1>
              </div>
            </div>
            <div className="search-filter-wrap">
              <div className="serach-wrap">
                <TextField
                  label="Search"
                  variant="outlined"
                  placeholder="Search by name, email"
                  onChange={handleSearch}
                  value={filter?.search}
                />
              </div>
            </div>
            <SupportTableList
              listData={listData}
              filter={filter}
              toggleSortOrder={toggleSortOrder}
              handleFilterSubmit={handleFilterSubmit}
              alPermissions={alPermissions}
            />
            {listData?.result?.length > 0 && (
              <PaginationSection
                filter={filter}
                listData={listData}
                handleChangePage={handleChangePage}
                handleChangePageLimit={handleChangePageLimit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getAllSupport: (data) => dispatch(getAllSupport(data)),
});
export default connect(mapState, mapDispatch)(SupportList);
