import "./index.css";
import logo from "assets/images/logo.svg";
import { Link } from "react-router-dom";

const AccountLeftSection = () => {
  return (
    <div className="left-section">
      <div className="logo-wrap">
        <Link to="/">
          <figure>
            <img src={logo} alt="Fivestar Logo" />
          </figure>
        </Link>
      </div>
    </div>
  );
};

export default AccountLeftSection;
