import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  Confirmation,
  ReportedTabSection,
  ModerationSection,
} from "components/molecules";
import {
  Link,
  useHistory,
  useParams,
  withRouter,
  useLocation,
} from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./index.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { connect } from "react-redux";
import {
  getVideoDetail,
  flagUnflagDeleteContent,
  updateReportStatus,
} from "store/actions";
import {
  convertObjToStringParams,
  showAction,
  showSection,
} from "utils/helper";
import { Loader } from "components/atoms";
import Moment from "react-moment";

const ReportDetailPost = (props) => {
  let history = useHistory();
  const {
    getVideoDetail,
    showLoader,
    flagUnflagDeleteContent,
    detail,
    updateReportStatus,
    alPermissions,
  } = props;
  const { videoId } = useParams();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const reportId = queryParams.get("reportId");
  const [reportStatus, setReportStatus] = React.useState(
    queryParams.get("reportStatus") || 1
  );

  const [parentCounter, setParentCounter] = React.useState(0);
  const [openConf, setOpenConf] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpenConf(false);
    },
    clickYes: () => {},
  });
  const [tabvalue, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 7))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchVideoDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (detail?.reportStatus && detail?.reportStatus !== reportStatus) {
      setReportStatus(detail?.reportStatus);
    }
  }, [detail]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchVideoDetail = () => {
    const apiData = { id: videoId };
    const params = convertObjToStringParams(apiData);
    return getVideoDetail({ params });
  };

  const handleClickOpen = ({ type }) => {
    let obj = { ...confirmationInfo };
    if (type === "mark-pending") {
      obj = {
        ...obj,
        title: "Mark as pending",
        description: `Are you sure you want to mark report as pending ?`,
        clickYes: () => {
          const apiData = {
            id: reportId,
            reportStatus: 1,
            reportType: 2,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) {
              fetchVideoDetail();
              setOpenConf(false);
              setReportStatus(1);
              // let newUrl = `${location.pathname}?reportId=${reportId}&reportStatus=1`;
              // history.push(newUrl);
              setParentCounter((pc) => pc + 1);
            }
          });
        },
      };
    } else if (type === "mark-resolved") {
      obj = {
        ...obj,
        title: "Mark as resolved",
        description: `Are you sure you want to mark report as resolved ?`,
        clickYes: () => {
          const apiData = {
            id: reportId,
            reportStatus: 2,
            reportType: 2,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) {
              fetchVideoDetail();
              setOpenConf(false);
              setReportStatus(2);
              // let newUrl = `${location.pathname}?reportId=${reportId}&reportStatus=2`;
              // history.push(newUrl);
              setParentCounter((pc) => pc + 1);
            }
          });
        },
      };
    } else if (type === "unflag") {
      obj = {
        ...obj,
        title: "Unflag content",
        description: `Are you sure you want to unflag ${detail?.caption} ?`,
        clickYes: () => {
          const apiData = { id: videoId, status: 1 };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) {
              fetchVideoDetail();
              setOpenConf(false);
            }
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete content",
        description: `Are you sure you want to delete ${detail?.caption} ?`,
        clickYes: () => {
          const apiData = { id: videoId, status: 3 };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) {
              setOpenConf(false);
              history.push("/reported-content");
            }
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Flag content",
        description: `Are you sure you want to flag ${detail?.caption} ?`,
        clickYes: () => {
          const apiData = { id: videoId, status: 2 };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) {
              fetchVideoDetail();
              setOpenConf(false);
            }
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpenConf(true);
  };

  const contentType = detail?.contentFormat ? detail?.contentFormat : "mp4";

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section report-post-detail">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="report-post-header">
              <div className="title-left">
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h1 className="title">Report Detail</h1>
              </div>
              {showAction(alPermissions, 7) && (
                <div className="btn-group">
                  <Button
                    variant="outlined"
                    color={reportStatus == 1 ? "success" : "error"}
                    onClick={() =>
                      handleClickOpen({
                        type: `mark-${
                          reportStatus == 1 ? "resolved" : "pending"
                        }`,
                      })
                    }
                  >
                    Mark {reportStatus == 1 ? "Resolved" : "Pending"}
                  </Button>
                  <Button
                    variant="outlined"
                    color={detail?.adminStatus === 1 ? "error" : "success"}
                    onClick={() =>
                      handleClickOpen({
                        type: detail?.adminStatus === 1 ? "flag" : "unflag",
                      })
                    }
                  >
                    {detail?.adminStatus === 1 ? "Flag" : "Unflag"} post
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleClickOpen({ type: "delete" })}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
            <div className="report-post-section">
              <div className="report-post-wrapper">
                {/* <div className="zoom-icon">
                  <IconButton aria-label="Example">
                    <CenterFocusWeakIcon />
                  </IconButton>
                </div> */}
                {detail?.contentUrl && detail?.contentUrl.length > 0 && (
                  <video controls poster={detail?.contentCoverImage}>
                    <source
                      src={detail?.contentUrl}
                      type={`video/${contentType}`}
                    />
                  </video>
                )}
              </div>
              <div className="report-post-description">
                <p>{detail?.caption}</p>
              </div>
            </div>
            <div className="report-info-section">
              <div className="flex-row">
                <div className="flex-col">
                  <label>
                    Posted on:{" "}
                    {
                      <Moment format="Do MMM YYYY h:mm A">
                        {detail?.createdAt}
                      </Moment>
                    }
                  </label>
                </div>
                <div className="flex-col">
                  <label>
                    Flagged Status: {detail?.adminStatus === 1 ? "No" : "Yes"}
                  </label>
                </div>
                <div className="flex-col">
                  <label>Fivestar score: {detail?.score}</label>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-col">
                  <label>Sport: {detail?.sport?.sportName}</label>
                </div>
                <div className="flex-col">
                  <label>
                    Athelte Name:{" "}
                    <Link to={`/athletes/${detail?.userData?.userId}`}>
                      {detail?.userData?.name}
                    </Link>
                  </label>
                </div>
              </div>
            </div>
            <div className="tabs-section">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabvalue}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label={`Reported Users (${
                          detail?.report_total_count ?? 0
                        })`}
                        value="1"
                      />
                      <Tab label={`Moderation`} value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <ReportedTabSection
                      contentId={videoId}
                      reportPage={true}
                      parentCounter={parentCounter}
                      fetchPageDetail={fetchVideoDetail}
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <ModerationSection contentId={videoId} />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={openConf}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.videoFeedInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getVideoDetail: (data) => dispatch(getVideoDetail(data)),
  flagUnflagDeleteContent: (data) => dispatch(flagUnflagDeleteContent(data)),
  updateReportStatus: (data) => dispatch(updateReportStatus(data)),
});
export default withRouter(connect(mapState, mapDispatch)(ReportDetailPost));
