import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { getAllStaticContent } from "store/actions";
import { convertObjToStringParams, showAction } from "utils/helper";
import { Loader } from "components/atoms";

const AboutUsTab = (props) => {
  const { getAllStaticContent, alPermissions, showLoader } = props;
  const [listData, setListData] = React.useState(null);

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { type: 4 };
    const params = convertObjToStringParams(apiData);
    getAllStaticContent({ params }).then((resp) => {
      setListData(resp);
    });
  };
  return (
    <div className="policy-wrapper">
      {showLoader && <Loader />}
      <div className="cms-content">
        <div className="cms-header">
          <h2>About Us</h2>
          {showAction(alPermissions, 4) && (
            <Tooltip title="Add">
              <Link to="/static-content/4/add" hidden>
                Add
              </Link>
            </Tooltip>
          )}
        </div>
        <div className="content-description">
          <div className="content-box">
            <div className="content-main">
              {listData?.content && parse(listData?.content)}
            </div>
            {showAction(alPermissions, 4) && (
              <div className="action-wrap">
                <Tooltip title="Edit">
                  <Link to="/static-content/4/edit">
                    <IconButton color="primary">
                      <EditIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getAllStaticContent: (data) => dispatch(getAllStaticContent(data)),
});

export default connect(mapState, mapDispatch)(AboutUsTab);
