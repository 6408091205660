import * as React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { changeStaticContent } from "store/actions";
import { LeftSidebar, HeaderSection } from "components/molecules";
import { Loader } from "components/atoms";
import { showSection } from "utils/helper";

const PrivacyPolicyAdd = (props) => {
  const { changeStaticContent, showLoader, alPermissions } = props;
  const history = useHistory();
  const { type } = useParams();
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 4))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  const getType = () => {
    let typeVal = "";
    let typeNum = parseInt(type);
    if (typeNum === 1) typeVal = "Privacy Policy";
    if (typeNum === 3) typeVal = "Data Deletion Policy";
    if (typeNum === 4) typeVal = "About Us";

    return typeVal;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      type: parseInt(type),
      content: value,
    };
    changeStaticContent(data).then((resp) => {
      if (resp) {
        setValue("");
        history.goBack();
      }
    });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="static-content-header">
              <div className="title-left">
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h1 className="title">Add {getType()}</h1>
              </div>
              <div className="btn-group">
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </div>
            <div className="text-editor">
              <ReactQuill theme="snow" onChange={setValue} value={value} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  changeStaticContent: (data) => dispatch(changeStaticContent(data)),
});

export default connect(mapState, mapDispatch)(PrivacyPolicyAdd);
