import { callApiAdvanced } from "utils/apiCaller";
import { toast_, loader_ } from "store/actions";
import { modulesArr } from "utils/helper";

const athleteMod = modulesArr.filter((item) => item.id === 7)[0];

const customHeaders = {
  moduleHeader: athleteMod.value,
};

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const getAllReports = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/${data.type}-reportList?${data.params.replaceAll(
        "#",
        "%23"
      )}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const updateReportStatus = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/report-status`,
      method: "PATCH",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const updateSingleReportStatus = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/report-status-id`,
      method: "PATCH",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getModerationKeys = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/moderate-video-id?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
