import PropTypes from "prop-types";
import Button from "@mui/material/Button";
const FilterSubmit = (props) => {
  const { name, customClass, onClickButton } = props;
  return (
    <Button
      variant="contained"
      className={customClass}
      onClick={onClickButton}
      type="submit"
    >
      {name}
    </Button>
  );
};

FilterSubmit.propTypes = {
  name: PropTypes.string,
  customClass: PropTypes.string,
  onClickButton: PropTypes.func,
};

export default FilterSubmit;
