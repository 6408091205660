import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory } from "react-router-dom";

const VideoBreadCrumb = () => {
  const history = useHistory();

  return (
    <div className="title-left">
      <ArrowBackIosNewIcon onClick={() => history.goBack()} />
      <h1 className="title">Video Details</h1>
    </div>
  );
};

export default VideoBreadCrumb;
