import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FlagIcon from "@mui/icons-material/Flag";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Confirmation } from "components/molecules";
import { connect } from "react-redux";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { ColumnWithSort } from "components/atoms";
import { flagUnflagDeleteContent } from "store/actions";
import { showAction, roundOff } from "utils/helper";

const VideoTableList = (props) => {
  const {
    listData,
    filter,
    toggleSortOrder,
    flagUnflagDeleteContent,
    handleFilterSubmit,
    alPermissions,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];
  const [open, setOpen] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {},
  });

  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "flag") {
      obj = {
        ...obj,
        title: "Flag content",
        description: `Are you sure you want to flag ${row.caption} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 2,
          };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete content",
        description: `Are you sure you want to delete ${row.caption} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 3,
          };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Unflag content",
        description: `Are you sure you want to unflag ${row.caption} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 1,
          };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        <figure>
          <FindInPageIcon />
        </figure>
        <h3>
          {listData?.result === undefined ? "Loading ..." : "No Data Found"}
        </h3>
      </div>
    );
  }
  return (
    <div className="table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Video Id</TableCell>
              <TableCell>Caption</TableCell>
              <TableCell>Uploaded by</TableCell>
              <TableCell>Fivestar Score</TableCell>
              <TableCell>Ranking</TableCell>
              <TableCell>
                <ColumnWithSort
                  title="Date & Time"
                  sortBy={filter?.sortBy}
                  currentKey="addedOn"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>

              {showAction(alPermissions, 6) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link to={`/video-feed/${row._id}`}>{row?.contentUUID}</Link>
                </TableCell>
                <TableCell>
                  <Tooltip title={row?.caption ? row?.caption : "NA"}>
                    <span>
                      {row.caption.length <= 10 && row.caption}
                      {row.caption.length > 10 &&
                        `${row.caption.slice(0, 10)} ...`}
                      {!row?.caption && "NA"}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Link
                    to={`/${
                      row.userData.userType === 1 ? "fans" : "athletes"
                    }/${row.userData.userId}`}
                  >
                    {row.userData.name}
                  </Link>
                </TableCell>
                <TableCell>{roundOff(row.rating?.avgRating)}</TableCell>
                <TableCell>{row.rating?.ratingCount}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY & h:mm A">{row.addedOn}</Moment>
                </TableCell>
                {showAction(alPermissions, 6) && (
                  <TableCell>
                    <div className="action-wrap">
                      {row.adminStatus === 1 ? (
                        <Tooltip title="Flag content">
                          <IconButton
                            onClick={() =>
                              handleClickOpen({ type: "flag", row })
                            }
                          >
                            <FlagIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Unflag content">
                          <IconButton
                            onClick={() =>
                              handleClickOpen({ type: "unflag", row })
                            }
                          >
                            <FlagIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  flagUnflagDeleteContent: (data) => dispatch(flagUnflagDeleteContent(data)),
});

export default connect(mapState, mapDispatch)(VideoTableList);
