import * as React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  LeftSidebar,
  HeaderSection,
  AddPositionDialogs,
  SportsPositionTableList,
  PaginationSection,
} from "components/molecules";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import "./index.css";
import { connect } from "react-redux";
import { getSportDetail, getSportPositions } from "store/actions";
import {
  convertObjToStringParams,
  getSubOrdinate,
  showAction,
  showSection,
} from "utils/helper";
import { Loader } from "components/atoms";
import SportsBlack from "assets/images/sports-black.svg";
import Button from "@mui/material/Button";

const SportDetail = (props) => {
  const history = useHistory();

  const {
    getSportDetail,
    showLoader,
    detail,
    getSportPositions,
    alPermissions,
  } = props;
  const { id } = useParams();
  const [openAddPosition, setOpenAddPosition] = React.useState(false);
  const [filter, setFilter] = React.useState({
    pageLimit: 10,
    pageNo: 1,
  });
  let [counter, setCounter] = React.useState(0);
  const [listData, setListData] = React.useState(null);
  const [pageMount, setPageMount] = React.useState(false);

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 10))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchSportDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSportDetail = () => {
    const apiData = { id };
    const params = convertObjToStringParams(apiData);
    getSportDetail({ params }).then((res) => {
      if (res && !pageMount) setPageMount(true);
    });
  };

  const showDefaultImage = (e) => {
    e.target.src = SportsBlack;
  };

  const handleAddPositionDialog = () => {
    setOpenAddPosition(true);
  };

  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      id,
    };
    return apiData;
  };

  const handleFilterSubmit = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getSportPositions({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        {showLoader && <Loader />}
        <div className="main-section sport-detail-list">
          <div className="sport-wrap">
            <div className="sport-title">
              <div className="title-left">
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h1 className="title-info">Sport Detail</h1>
              </div>
            </div>
            {pageMount && (
              <div className="sport-detail-info">
                <div className="info-sport">
                  <div className="info-sport-title">
                    <label>Sport Name:</label>
                    <strong>{detail?.sportName}</strong>
                  </div>
                  <div className="info-sport-description">
                    <label>Sport Icon:</label>
                    <div className="info-sport-icon">
                      <figure>
                        <img
                          src={detail?.sportImg}
                          alt="Sports Icon"
                          onError={showDefaultImage}
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="edit-text">
                  {showAction(alPermissions, 10) && (
                    <Link to={`/sports/${detail?._id}/edit`}>
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </Link>
                  )}
                </div>
              </div>
            )}
            {detail?.ordinateType && detail?.ordinateType != 4 && (
              <>
                <div className="sport-title">
                  <div className="title-left">
                    <h2 className="sub-title">
                      {getSubOrdinate(detail?.ordinateType || 1)}s
                    </h2>
                  </div>
                  <div className="btn-group">
                    <div className="add-position-dialog">
                      {showAction(alPermissions, 10) && (
                        <Button
                          variant="contained"
                          onClick={handleAddPositionDialog}
                        >
                          Add a {getSubOrdinate(detail?.ordinateType || 1)}
                        </Button>
                      )}
                      <AddPositionDialogs
                        open={openAddPosition}
                        setOpen={setOpenAddPosition}
                        sport={detail}
                        fetchSportDetail={handleFilterSubmit}
                        ordinateName={getSubOrdinate(detail?.ordinateType || 1)}
                      />
                    </div>
                  </div>
                </div>
                <SportsPositionTableList
                  listData={listData}
                  filter={filter}
                  fetchSportDetail={handleFilterSubmit}
                  sport={detail}
                  ordinateName={getSubOrdinate(detail?.ordinateType || 1)}
                  alPermissions={alPermissions}
                />
                {listData?.result?.length > 0 && (
                  <PaginationSection
                    filter={filter}
                    listData={listData}
                    handleChangePage={handleChangePage}
                    handleChangePageLimit={handleChangePageLimit}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.sportInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getSportDetail: (data) => dispatch(getSportDetail(data)),
  getSportPositions: (data) => dispatch(getSportPositions(data)),
});

export default connect(mapState, mapDispatch)(SportDetail);
