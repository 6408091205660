import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import "./index.css";
import { customTextFieldValidator } from "utils/validations";
import { connect } from "react-redux";
import { addPosition } from "store/actions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function AddPositionDialogs(props) {
  const { open, setOpen, sport, fetchSportDetail, addPosition, ordinateName } =
    props;

  const [formData, setFormData] = React.useState({
    name: "",
    errors: {},
  });

  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const handleClose = () => {
    setFormData({
      name: "",
      errors: {},
    });
    setOpen(false);
  };

  const handleSubmit = () => {
    if (checkForError()) return false;
    const data = { name: formData.name.trim(), id: sport._id };
    addPosition(data).then((res) => {
      if (res) {
        fetchSportDetail();
        handleClose();
      }
    });
  };

  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "name") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 3 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter name !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            false,
            "Please enter only alphabets with spaces in between !",
          ],
          customRegex: [
            true,
            "Please enter only alphabets, digits, /, &, -, spaces in between !",
            /^[a-z/0-9-& ]+$/i,
          ],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.name = customTextFieldValidator({
        min: [3, "Min. length is 3 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.name,
        req: [true, "Please enter name !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          false,
          "Please enter only alphabets with spaces in between !",
        ],
        customRegex: [
          true,
          "Please enter only alphabets, digits, /, &, -, spaces in between !",
          /^[a-z/0-9-& ]+$/i,
        ],
      });

      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <BootstrapDialogTitle
        className="dialog-title"
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Add New {ordinateName}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography component="span" mb={1}>
          <div className="position-text add-position">
            <label className="form-label">
              Enter the name of the {ordinateName} you want to create*
            </label>
            <TextField
              label={`Type a ${ordinateName} Name`}
              variant="outlined"
              placeholder={`Type a ${ordinateName} Name`}
              value={formData?.name}
              onChange={onChangeForm("name")}
            />
          </div>
          {formData?.errors?.name && (
            <span className="error-msg">{formData?.errors?.name}</span>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Continue
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
});
const mapDispatch = (dispatch) => ({
  addPosition: (data) => dispatch(addPosition(data)),
});
export default connect(mapState, mapDispatch)(AddPositionDialogs);
