import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  LeftSidebar,
  HeaderSection,
  EarningTableList,
  PaginationSection,
} from "components/molecules";
import TextField from "@mui/material/TextField";
import "./index.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllEarnings } from "store/actions";
import { useHistory } from "react-router-dom";
import { convertObjToStringParams, showSection } from "utils/helper";
import { Loader } from "components/atoms";

const EarningsList = (props) => {
  const { getAllEarnings, showLoader, alPermissions } = props;
  const [listData, setListData] = React.useState(null);

  const history = useHistory();

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: 10,
    pageNo: 1,
    daysFilter: 1,
  });

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 1))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  //Debouncing For Search
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    newFilter.pageNo = 1;

    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      daysFilter: filter.daysFilter,
    };
    if (filter.search) apiData.search = filter.search;
    return apiData;
  };

  const handleFilterSubmit = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getAllEarnings({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;
    newFilter.pageNo = 1;

    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Earnings Management</h1>
              </div>
            </div>
            <div className="card-section">
              <div className="card-row">
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Earnings</h2>
                    <div className="info-value">
                      <p>{`$${listData?.total}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-wrap">
              <div className="serach-wrap">
                <TextField
                  label="Search"
                  variant="outlined"
                  placeholder="Search by user"
                  onChange={handleSearch}
                  value={filter?.search}
                />
              </div>
            </div>
            <div className="filterAction">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  value={filter?.daysFilter}
                  onChange={handleChangeFilter("daysFilter")}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Today"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="In Last 7 days"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="In Last 30 days"
                  />
                  <FormControlLabel value={4} control={<Radio />} label="All" />
                </RadioGroup>
              </FormControl>
            </div>
            {showLoader && <Loader />}
            <EarningTableList listData={listData} />
            {listData?.result?.length > 0 && (
              <PaginationSection
                filter={filter}
                listData={listData}
                handleChangePage={handleChangePage}
                handleChangePageLimit={handleChangePageLimit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getAllEarnings: (data) => dispatch(getAllEarnings(data)),
});

EarningsList.propTypes = {
  getAllEarnings: PropTypes.func,
  showLoader: PropTypes.bool,
};
export default connect(mapState, mapDispatch)(EarningsList);
