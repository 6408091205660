import * as React from "react";
import { Link, useParams, useHistory, withRouter } from "react-router-dom";
import { LeftSidebar, HeaderSection, Confirmation } from "components/molecules";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Document from "assets/images/cover.jpg";
import User from "assets/images/user-pic.png";
import SportsBlack from "assets/images/sports-black.svg";
import DownloadIcon from "@mui/icons-material/Download";
import "./index.css";
import { connect } from "react-redux";
import {
  getAthleteDetail,
  getAthleteDetail_,
  blockUnblockDeleteUser,
} from "store/actions";
import {
  convertObjToStringParams,
  showAction,
  showSection,
} from "utils/helper";
import { Loader } from "components/atoms";
import Tooltip from "@mui/material/Tooltip";
import Moment from "react-moment";

const AthleteDetail = (props) => {
  const {
    getAthleteDetail,
    showLoader,
    blockUnblockDeleteUser,
    detail,
    alPermissions,
    getAthleteDetail_,
  } = props;
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {},
  });
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 1))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchAthleteDetail();
    return () => {
      getAthleteDetail_(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAthleteDetail = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getAthleteDetail({ params });
  };

  const getApiData = () => {
    return {
      id,
    };
  };

  const handleClickOpen = ({ type }) => {
    let obj = { ...confirmationInfo };
    if (type === "activate") {
      obj = {
        ...obj,
        title: "Activate user",
        description: `Are you sure you want to activate ${detail?.details?.name} ?`,
        clickYes: () => {
          const apiData = {
            id: id,
            status: 1,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) fetchAthleteDetail();
            setOpen(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete user",
        description: `Are you sure you want to delete ${detail?.details?.name} ?`,
        clickYes: () => {
          const apiData = {
            id: id,
            status: 3,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) {
              setOpen(false);
              history.push("/athletes");
            }
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Block user",
        description: `Are you sure you want to block ${detail?.details?.name} ?`,
        clickYes: () => {
          const apiData = {
            id: id,
            status: 2,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) fetchAthleteDetail();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };
  const showDefaultImage = (e) => {
    e.target.src = SportsBlack;
  };

  const showDefaultCoverImage = (e) => {
    e.target.src = Document;
  };
  const showDefaultProfileImage = (e) => {
    e.target.src = User;
  };
  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="athlete-wrap">
            <div className="athlete-title">
              <div className="title-left">
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h1 className="section-title">Athlete Details</h1>
              </div>
              {showAction(alPermissions, 1) && (
                <div className="btn-group">
                  <Button
                    color={detail?.details?.status === 1 ? "error" : "success"}
                    variant="outlined"
                    onClick={() =>
                      handleClickOpen({
                        type:
                          detail?.details?.status === 1
                            ? "de-activate"
                            : "activate",
                      })
                    }
                  >
                    {detail?.details?.status === 1 ? "Block" : "Activate"}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleClickOpen({ type: "delete" })}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
            <div className="user-wrap">
              <h3 className="section-heading">Account Details</h3>
              <div className="user-inner">
                <div className="user-inner-row">
                  <div className="user-col">
                    <label className="user-detail">Name</label>
                    <p>{detail?.details?.name}</p>
                  </div>
                  <div className="user-col">
                    <label className="user-detail">Mobile Number</label>
                    <p>
                      {detail?.details?.countryCode !== "NA"
                        ? detail?.details?.countryCode
                        : ""}{" "}
                      {detail?.details?.phoneNo}
                    </p>
                  </div>
                  <div className="user-col">
                    <label className="user-detail">Email Address</label>
                    <p>{detail?.details?.email}</p>
                  </div>
                  <div className="user-col top-spacing">
                    <label className="user-detail">Subscription</label>
                    <p>{"N/A"}</p>
                    <div className="subscription-link">
                      <Link to={`/athletes/${id}/subscriptions`}>
                        Subscription History
                      </Link>
                    </div>
                  </div>
                  {/* <div className="user-col top-spacing">
                    <label className="user-detail">Fivestar Rating</label>
                    <p>{detail?.details?.avg_rate}</p>
                  </div> */}
                  <div className="user-col top-spacing">
                    <label className="user-detail">Sucessfull Invitation</label>
                    <p>{detail?.details?.invites}</p>
                  </div>
                  <div className="user-col top-spacing">
                    <label className="user-detail">Fan Rating</label>
                    <p>10 (static)</p>
                  </div>
                </div>
                <div className="user-doc" hidden>
                  <h4 className="section-subheading">Documents</h4>
                  <div className="user-row">
                    <div className="doc-col">
                      <label className="user-detail">Identification</label>
                      <div className="doc-info">
                        <Tooltip title="Download">
                          <div className="download-icon">
                            <Link>
                              <DownloadIcon />
                            </Link>
                          </div>
                        </Tooltip>
                        <figure>
                          <img src={Document} alt="Document Upload" />
                        </figure>
                      </div>
                    </div>
                    <div className="doc-col">
                      <label className="user-detail">Passport</label>
                      <div className="doc-info">
                        <Tooltip title="Download">
                          <div className="download-icon">
                            <Link>
                              <DownloadIcon />
                            </Link>
                          </div>
                        </Tooltip>
                        <figure>
                          <img src={Document} alt="Document Upload" />
                        </figure>
                      </div>
                    </div>
                    <div className="doc-col">
                      <label className="user-detail">Green card</label>
                      <div className="doc-info">
                        <Tooltip title="Download">
                          <div className="download-icon">
                            <Link>
                              <DownloadIcon />
                            </Link>
                          </div>
                        </Tooltip>
                        <figure>
                          <img src={Document} alt="Document Upload" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-wrap">
              <h3 className="section-heading">Basic Details</h3>
              <div className="user-profile-inner">
                <div className="user-left">
                  <div className="user-image">
                    <figure>
                      <img
                        src={
                          detail?.details?.profileImage &&
                          detail?.details?.profileImage !== "NA"
                            ? detail?.details?.profileImage
                            : User
                        }
                        alt="User"
                        onError={showDefaultProfileImage}
                      />
                    </figure>
                  </div>
                </div>
                <div className="user-right margin">
                  <div className="user-inner-row">
                    <div className="user-col">
                      <label className="user-detail">Username</label>
                      <p>{detail?.details?.username}</p>
                    </div>
                    <div className="user-col">
                      <label className="user-detail">Date Of Birth</label>
                      <p>
                        <Moment format="DD MMM, YYYY">
                          {detail?.details?.personalDetails?.dob}
                        </Moment>
                      </p>
                    </div>
                    <div className="user-col">
                      <label className="user-detail">Zip</label>
                      <p>
                        {detail?.details?.zipcode
                          ? detail?.details?.zipcode
                          : "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-inner-row">
                <div className="user-col top-spacing">
                  <div className="user-bio">
                    <label className="user-detail">Cover Image</label>
                    <div className="cover-img">
                      <figure>
                        <img
                          src={
                            detail?.details?.coverImage &&
                            detail?.details?.coverImage !== "NA"
                              ? detail?.details?.coverImage
                              : Document
                          }
                          alt="Cover"
                          onError={showDefaultCoverImage}
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="user-col-bio top-spacing">
                  <label className="user-detail">Bio</label>
                  <p className="bio-description">
                    {detail?.details?.personalDetails?.bio}
                  </p>
                </div>
              </div>
            </div>
            <div className="user-wrap">
              <h3 className="section-heading">Athlete Details</h3>
              <div className="user-inner">
                <div className="user-row">
                  <div className="user-col">
                    <label className="user-detail">Level</label>
                    <p>
                      {!detail?.details?.education?.educationLevel && "NA"}
                      {detail?.details?.education?.educationLevel === 1 &&
                        "Youth"}
                      {detail?.details?.education?.educationLevel === 2 &&
                        "High School"}
                      {detail?.details?.education?.educationLevel === 3 &&
                        "College"}
                      {detail?.details?.education?.educationLevel === 4 &&
                        "Professional"}
                      {detail?.details?.education?.educationLevel === 5 &&
                        "Recreation"}
                      {detail?.details?.education?.educationLevel === 6 &&
                        "Lifestyle"}
                    </p>
                  </div>
                  <div className="user-col">
                    <label className="user-detail">Height</label>
                    <p>
                      {detail?.details?.personalDetails?.higth?.feet &&
                        `${detail?.details?.personalDetails?.higth?.feet} ft.`}{" "}
                      {detail?.details?.personalDetails?.higth?.inch &&
                        `${detail?.details?.personalDetails?.higth?.inch} in.`}
                    </p>
                  </div>
                  <div className="user-col">
                    <label className="user-detail">Weight</label>
                    <p>{detail?.details?.personalDetails?.weight} lbs</p>
                  </div>
                  <div className="user-col top-spacing">
                    <label className="user-detail">School</label>
                    <p>
                      {detail?.details?.education?.schoolName
                        ? detail?.details?.education?.schoolName
                        : "NA"}
                    </p>
                  </div>
                  <div className="user-col top-spacing">
                    <label className="user-detail">Graduating year</label>
                    <p>
                      {detail?.details?.education?.passoutYear
                        ? detail?.details?.education?.passoutYear
                        : "NA"}
                    </p>
                  </div>
                </div>
                <h4 className="sub-title">Sports</h4>
                <div className="user-inner-row">
                  <div className="scroll">
                    {detail?.details?.sports.length > 0 ? (
                      detail?.details?.sports.map((sp, i) => {
                        return (
                          <div className="user-col" key={sp._id}>
                            <div className="sports-info">
                              <div className="sports-card-head">
                                <div className="sport-logo">
                                  <figure>
                                    <img src={sp.sportImg} alt="Icon" />
                                  </figure>
                                </div>
                                <h3 className="sports-title">{sp.sportName}</h3>
                              </div>
                              <div className="sports-card-body">
                                <div className="sports-flex">
                                  <div className="athlete-sport-info">
                                    <label className="user-detail">
                                      Positions
                                    </label>
                                    <p className="text2">
                                      {sp.Positions.map((po) => po.name).join(
                                        ", "
                                      )}
                                    </p>
                                  </div>
                                </div>
                                {sp.teams.length > 0 &&
                                  sp.teams.map((te) => {
                                    return (
                                      <div className="sports-flex" key={te._id}>
                                        <div className="athlete-sport-info">
                                          <label className="user-detail">
                                            {te?.teamType === 1 && "School"}
                                            {te?.teamType === 2 && "Club"}
                                            {te?.teamType === 3 &&
                                              "Recreation"}{" "}
                                            Team
                                          </label>
                                          <p className="text2">
                                            {te?.teamName}
                                          </p>
                                        </div>
                                        <div className="athlete-sport-info-des">
                                          <label className="user-detail">
                                            Coach
                                          </label>
                                          <p className="text2">
                                            {te?.coachName
                                              ? te?.coachName
                                              : "NA"}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="like-sport-text">NA</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="user-wrap">
              <h3 className="section-heading">Liked Sports</h3>
              <div className="user-inner">
                <div className="user-row scroll">
                  {detail?.details?.likedSport &&
                  detail?.details?.likedSport.length > 0 ? (
                    detail?.details?.likedSport.map((item) => {
                      return (
                        <div className="like-col" key={item._id}>
                          <div className="sport-title-sec">
                            <div className="sport-logo like">
                              <figure>
                                <img
                                  src={item.sportImg}
                                  alt="Icon"
                                  onError={showDefaultImage}
                                />
                              </figure>
                            </div>
                            <h3 className="like-title">{item.sportName}</h3>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="like-sport-text">NA</p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-wrap">
              <div className="user-row">
                <div className="user-col">
                  <div className="followers-info">
                    <label className="followers-title">Followers</label>
                    <div className="info-text">
                      <Link to={`/athletes/${id}/followers`}>
                        {detail?.followerCount} Followers
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="user-col">
                  <div className="followers-info">
                    <label className="followers-title">Following</label>
                    <div className="info-text">
                      <Link to={`/athletes/${id}/followings`}>
                        {detail?.followingCount} Following
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="user-col">
                  <div className="followers-info">
                    <label className="followers-title">Video Library</label>
                    <div className="info-text">
                      <Link to={`/athletes/${id}/video-library`}>
                        {detail?.VideoCount} Video
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="user-col user-top-spacing">
                  <div className="followers-info">
                    <label className="followers-title">Video Rated</label>
                    <div className="info-text">
                      <Link to={`/athletes/${id}/video-rated`}>
                        {detail?.RateCount} Video
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.athleteInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getAthleteDetail: (data) => dispatch(getAthleteDetail(data)),
  blockUnblockDeleteUser: (data) => dispatch(blockUnblockDeleteUser(data)),
  getAthleteDetail_: (data) => dispatch(getAthleteDetail_(data)),
});
export default withRouter(connect(mapState, mapDispatch)(AthleteDetail));
