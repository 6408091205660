import axios from "axios";
import { API_URL, API_KEY } from "utils/constants";

export const callApi = (
  endpoint,
  method = "POST",
  body,
  fileToUpload = false,
  customHeaders = null
) => {
  localStorage.setItem("loader", true);
  let headers = {};
  let authToken = false;
  let baseUrl =
    endpoint.includes("http:") || endpoint.includes("https:")
      ? endpoint
      : `${API_URL}${endpoint}`;

  let isTokenAvailable = localStorage.getItem("token");

  if (isTokenAvailable) {
    authToken = isTokenAvailable;
  }

  if (method) {
    headers["X-Requested-With"] = "XMLHttpRequest";
    headers["operationHeader"] = 2;
    if (method === "GET") headers["operationHeader"] = 1;
  }

  headers["Accept"] = "application/json";
  headers["Access-Control-Allow-Origin"] = "*";
  headers["Access-Control-Allow-Methods"] = "GET, OPTIONS, POST, PUT, PATCH";

  if (fileToUpload) {
    headers["Content-Type"] = "multipart/form-data";
  }

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
    headers["api-key"] = `${API_KEY}`;
  }

  let config = {
    method: method,
    url: baseUrl,
    data: body,
    headers: headers,
  };

  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response; // added  this here
    });
};

export const callApiAdvanced = ({
  endpoint,
  method = "POST",
  body,
  fileToUpload = false,
  customHeaders = null,
}) => {
  localStorage.setItem("loader", true);
  let headers = {};
  let authToken = false;
  let baseUrl =
    endpoint.includes("http:") || endpoint.includes("https:")
      ? endpoint
      : `${API_URL}${endpoint}`;

  let isTokenAvailable = localStorage.getItem("token");

  if (isTokenAvailable) {
    authToken = isTokenAvailable;
  }

  if (method) {
    headers["X-Requested-With"] = "XMLHttpRequest";
    headers["operationHeader"] = 2;
    if (method === "GET") headers["operationHeader"] = 1;
  }

  if (customHeaders) {
    Object.keys(customHeaders).forEach((k) => {
      headers[k] = customHeaders[k];
    });
  }

  headers["Accept"] = "application/json";
  headers["Access-Control-Allow-Origin"] = "*";
  headers["Access-Control-Allow-Methods"] = "GET, OPTIONS, POST, PUT, PATCH";

  if (fileToUpload) {
    headers["Content-Type"] = "multipart/form-data";
  }

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
    headers["api-key"] = `${API_KEY}`;
  }

  let config = {
    method: method,
    url: baseUrl,
    data: body,
    headers: headers,
  };

  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response; // added  this here
    });
};
