import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BlockIcon from "@mui/icons-material/Block";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import "./index.css";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Confirmation } from "components/molecules";
import { blockUnblockDeleteUser, updateReportStatus } from "store/actions";
import { connect } from "react-redux";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { showAction } from "utils/helper";

const UsersTableList = (props) => {
  const {
    listData,
    filter,
    blockUnblockDeleteUser,
    handleFilterSubmit,
    toggleSortOrder,
    updateReportStatus,
    alPermissions,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData.result : [];
  const [open, setOpen] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {},
  });

  // const startVal =
  //   listData?.page && listData.page !== 1
  //     ? (listData?.page - 1) * filter.pageLimit
  //     : 0;

  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "mark-pending") {
      obj = {
        ...obj,
        title: "Mark as pending",
        description: `Are you sure you want to mark report as pending ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            reportStatus: 1,
            reportType: 1,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "mark-resolved") {
      obj = {
        ...obj,
        title: "Mark as resolved",
        description: `Are you sure you want to mark report as resolved ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            reportStatus: 2,
            reportType: 1,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "activate") {
      obj = {
        ...obj,
        title: "Activate user",
        description: `Are you sure you want to activate ${row.name} ?`,
        clickYes: () => {
          const apiData = {
            id: row.userId,
            status: 1,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete user",
        description: `Are you sure you want to delete ${row.name} ?`,
        clickYes: () => {
          const apiData = {
            id: row.userId,
            status: 3,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) {
              handleFilterSubmit();
            }
            setOpen(false);
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Block user",
        description: `Are you sure you want to block ${row.name} ?`,
        clickYes: () => {
          const apiData = {
            id: row.userId,
            status: 2,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        <figure>
          <FindInPageIcon />
        </figure>
        <h3>
          {listData?.result === undefined ? "Loading ..." : "No Data Found"}
        </h3>
      </div>
    );
  }

  return (
    <div className="user-tab table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Reported User</TableCell>
              <TableCell>Report Count</TableCell>
              <TableCell>Status</TableCell>
              {showAction(alPermissions, 7) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link
                    to={`/${row.userType === 1 ? "fans" : "athletes"}/${
                      row._id
                    }`}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {row.reportCount} User
                  {[0, 1].includes(row.reportCount) ? "" : "s"}
                </TableCell>
                <TableCell
                  className={`status-${
                    row.reportStatus === 1 ? "block" : "unblock"
                  }`}
                >
                  {row.reportStatus === 1 ? "Pending" : "Resolved"}
                  {/* Resolved */}
                </TableCell>
                {showAction(alPermissions, 7) && (
                  <TableCell>
                    <div className="action-wrap">
                      <Tooltip
                        title={`Mark ${
                          row.reportStatus === 1 ? "Resolved" : "Pending"
                        }`}
                      >
                        <IconButton
                          onClick={() =>
                            handleClickOpen({
                              type: `mark-${
                                row.reportStatus === 1 ? "resolved" : "pending"
                              }`,
                              row,
                            })
                          }
                        >
                          <AssignmentTurnedInIcon
                            color={row.reportStatus === 1 ? "success" : "error"}
                          />
                        </IconButton>
                      </Tooltip>
                      {row.status === 1 ? (
                        <Tooltip title="Block">
                          <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="span"
                            onClick={() =>
                              handleClickOpen({ type: "de-activate", row })
                            }
                          >
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Activate">
                          <IconButton
                            color="success"
                            onClick={() =>
                              handleClickOpen({ type: "activate", row })
                            }
                          >
                            <CheckCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Detail">
                        <Link
                          to={`/reported-content/user/${row.userId}?reportId=${row._id}&reportStatus=${row.reportStatus}`}
                        >
                          <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="span"
                          >
                            <RemoveRedEyeOutlinedIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});

const mapDispatch = (dispatch) => ({
  blockUnblockDeleteUser: (data) => dispatch(blockUnblockDeleteUser(data)),
  updateReportStatus: (data) => dispatch(updateReportStatus(data)),
});
export default connect(mapState, mapDispatch)(UsersTableList);
