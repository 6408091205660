import { GET_SUPPORT_DETAIL } from "store/types";

const initialState = {
  current: null,
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPORT_DETAIL:
      state.current = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default supportReducer;
