import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  FanRankedTabSection,
  FanTaggedTabSection,
  FanReportedTabSection,
} from "components/molecules";
import { FanVideoBreadCrumb } from "components/atoms";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import "./index.css";
import video from "assets/videos/mov_bbb.mp4";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const FanVideoDetails = () => {
  const [tabvalue, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section fan-video-detail">
          <div className="main-section-inner">
            <div className="video-feed-header">
              <FanVideoBreadCrumb />
              <div className="btn-group">
                <Button variant="outlined" color="error">
                  Flag post
                </Button>
                <Button variant="contained" color="error">
                  Delete
                </Button>
              </div>
            </div>
            <div className="fan-video-section">
              <div className="fan-video-wrapper">
                {/* <div className="zoom-icon">
                  <IconButton aria-label="Example">
                    <CenterFocusWeakIcon />
                  </IconButton>
                </div> */}
                <figure>
                  <video controls>
                    <source src={video} type="video/mp4" />
                  </video>
                </figure>
              </div>
              <div className="fan-video-description">
                <p>
                  While a picture can tell a thousand words, words can enhance a
                  picture by telling a story, providing context, or adding an
                  air of mystery. Similarly, Instagram captions can help
                  complete your Instagram post. You might add an Instagram
                  caption to direct customers to your bio link, share selfie
                  quotes, or increase social media engagement.
                </p>
              </div>
            </div>
            <div className="fan-video-info-section">
              <div className="flex-row">
                <div className="flex-col">
                  <label>Posted on: 13th Feb 202012:34PM</label>
                </div>
                <div className="flex-col">
                  <label>Flagged Status: No</label>
                </div>
                <div className="flex-col">
                  <label>Fivestar score: 4.5</label>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-col">
                  <label>Sport: Cricket</label>
                </div>
                <div className="flex-col">
                  <label>
                    Fan Name: <Link to="">Ashley green</Link>
                  </label>
                </div>
              </div>
            </div>
            <div className="tabs-section">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabvalue}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Rating by (321 Users)" value="1" />
                      <Tab label="Tagged users (12)" value="2" />
                      <Tab label="Reported by (12 user)" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <FanRankedTabSection />
                  </TabPanel>
                  <TabPanel value="2">
                    <FanTaggedTabSection />
                  </TabPanel>
                  <TabPanel value="3">
                    <FanReportedTabSection />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FanVideoDetails;
