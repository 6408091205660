import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  PaginationSection,
  FanFollowersTableList,
} from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FilterSubmit, FilterReset, Loader } from "components/atoms";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getFollowerFollowing } from "store/actions";
import { convertObjToStringParams, showSection } from "utils/helper";

const FanFollowers = (props) => {
  const history = useHistory();
  const { id: userId } = useParams();
  const { getFollowerFollowing, showLoader, alPermissions } = props;
  const [listData, setListData] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    from: "",
    to: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "addedOn", val: -1 },
    location: "",
    ranking: "",
    subscriptionType: "",
    status: "",
    error: "",
  });

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 2))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

   //Debouncing For Search
   const debounceCount = React.useRef(0);
   const api = () =>
     new Promise((res) => {
       setTimeout(() => {
         res(1);
       }, 500);
     });
 
   const handleSearch = (e) => {
     const newFilter = { ...filter };
     newFilter["search"] = e.target.value;
     setFilter(newFilter);
 
     debounceCount.current += 1;
     const localDebounceCount = debounceCount.current;
     api().then((d) => {
       if (localDebounceCount !== debounceCount.current) {
         return;
       }
       setCounter((pc) => pc + 1);
     });
   };

  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;

    if (newFilter.from && newFilter.to && ["from", "to"].includes(key)) {
      if (newFilter.from > newFilter.to) {
        newFilter.error = "Please select valid age options !";
      } else {
        newFilter.error = "";
      }
    }
    setFilter(newFilter);
  };

  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      //sortKey: filter.sortBy.key,
      //sortOrder: filter.sortBy.val,
      userId,
      type: 1, // follower
    };
    if (filter.from) apiData.from = filter.from;
    if (filter.to) apiData.to = filter.to;
    if (filter.search) apiData.search = filter.search;
    if (filter.location) apiData.location = filter.location;
    if (filter.ranking) apiData.ranking = filter.ranking;
    if (filter.subscriptionType) apiData.subscription = filter.subscriptionType;
    if (filter.status) apiData.status = filter.status;
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    setAnchorEl(null);
    getFollowerFollowing({ params, userType: 1 }).then((apiData) => {
      setListData(apiData);
    });
  };

  const handleFilterReset = () => {
    const newFilter = { ...filter };
    newFilter.from = "";
    newFilter.to = "";
    newFilter.location = "";
    newFilter.ranking = "";
    newFilter.error = "";
    newFilter.subscriptionType = "";
    newFilter.status = "";
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const renderAgeOptions = (key) => {
    let options = [];
    for (let i = 13; i <= 100; i++) {
      let item = (
        <MenuItem value={i} key={i + key}>
          {i} Years
        </MenuItem>
      );
      options.push(item);
    }
    return options;
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="title-left">
                <ArrowBackIosNewIcon onClick={() => history.go(-2)} />
                <h1 className="section-title">Fan Details</h1>
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h2 className="section-sub-title">Fan Followers</h2>
              </div>
            </div>
            <div className="search-filter-wrap">
              <div className="serach-wrap">
                <TextField
                  label="Search"
                  variant="outlined"
                  placeholder="Search by name"
                  onChange={handleSearch}
                  value={filter?.search}
                />
              </div>
              <div className="filter-btn">
                <Tooltip title="Filter">
                  <IconButton
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    color="primary"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <div className="filterPopup">
                    <div className="age-group">
                      <label className="textLabel">Age group</label>
                      <div className="filter-row">
                        <div className="filter-col bottom-spacing">
                          <FormControl fullWidth>
                            <InputLabel>From</InputLabel>
                            <Select
                              label="From"
                              placeholder="From"
                              value={filter?.from}
                              onChange={handleChangeFilter("from")}
                            >
                              {renderAgeOptions("from")}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="filter-col">
                          <FormControl fullWidth>
                            <InputLabel>To</InputLabel>
                            <Select
                              label="To"
                              placeholder="To"
                              value={filter?.to}
                              onChange={handleChangeFilter("to")}
                            >
                              {renderAgeOptions("to")}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    {filter?.error && (
                      <span className="error-msg">{filter.error}</span>
                    )}
                    <div className="filter-text">
                      <label className="form-label">Location</label>
                      <TextField
                        type="number"
                        label="Select"
                        variant="outlined"
                        value={filter?.location}
                        onChange={handleChangeFilter("location")}
                        placeholder="Enter Zipcode"
                      />
                    </div>
                    {/* <div className="filter-text">
                      <label className="form-label">Ranking</label>
                      <TextField
                        type="number"
                        label="Select"
                        variant="outlined"
                        placeholder="Select"
                        value={filter?.ranking}
                        onChange={handleChangeFilter("ranking")}
                      />
                    </div> */}
                    <div className="filter-text">
                      <label className="form-label">Subscription Type</label>
                      <FormControl fullWidth>
                        <InputLabel>Subscription Type</InputLabel>
                        <Select
                          label="Subscription Type"
                          placeholder="Select"
                          value={filter?.subscriptionType}
                          onChange={handleChangeFilter("subscriptionType")}
                        >
                          <MenuItem value="3">Non Premium User</MenuItem>
                          <MenuItem value="1">Monthly</MenuItem>
                          <MenuItem value="2">Yearly</MenuItem>
                          {/* <MenuItem value="4">Free trial</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="filter-text">
                      <label className="form-label">Status</label>
                      <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          label="Status"
                          placeholder="Select"
                          value={filter?.status}
                          onChange={handleChangeFilter("status")}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={2}>Blocked</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="btn-wrap">
                      <FilterReset
                        name="Reset"
                        onClickButton={handleFilterReset}
                      />
                      <FilterSubmit
                        name="Apply Filter"
                        onClickButton={handleFilterSubmit}
                      />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            {showLoader && <Loader />}
            <FanFollowersTableList
              listData={listData}
              filter={filter}
              toggleSortOrder={toggleSortOrder}
              handleFilterSubmit={handleFilterSubmit}
              alPermissions={alPermissions}
            />
            {listData?.result?.length > 0 && (
              <PaginationSection
                filter={filter}
                listData={listData}
                handleChangePage={handleChangePage}
                handleChangePageLimit={handleChangePageLimit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getFollowerFollowing: (data) => dispatch(getFollowerFollowing(data)),
});
export default connect(mapState, mapDispatch)(FanFollowers);
