import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  RankedTabSection,
  TaggedTabSection,
  ReportedTabSection,
  Confirmation,
} from "components/molecules";
import { VideoBreadCrumb } from "components/atoms";
import { Link, useParams, useHistory, withRouter } from "react-router-dom";
import Button from "@mui/material/Button";
import "./index.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { connect } from "react-redux";
import {
  getVideoDetail,
  getVideoDetail_,
  flagUnflagDeleteContent,
} from "store/actions";
import {
  convertObjToStringParams,
  showAction,
  showSection,
} from "utils/helper";
import { Loader } from "components/atoms";
import Moment from "react-moment";

const VideoDetail = (props) => {
  const {
    getVideoDetail,
    showLoader,
    flagUnflagDeleteContent,
    detail,
    alPermissions,
    getVideoDetail_,
  } = props;
  const { videoId } = useParams();
  const [openConf, setOpenConf] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpenConf(false);
    },
    clickYes: () => {},
  });
  const history = useHistory();
  const [tabvalue, setValue] = React.useState("1");

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 6))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchVideoDetail();
    return () => {
      getVideoDetail_(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchVideoDetail = () => {
    const apiData = { id: videoId };
    const params = convertObjToStringParams(apiData);
    return getVideoDetail({ params });
  };

  const handleClickOpen = ({ type }) => {
    let obj = { ...confirmationInfo };
    if (type === "unflag") {
      obj = {
        ...obj,
        title: "Unflag content",
        description: `Are you sure you want to unflag ${detail?.caption} ?`,
        clickYes: () => {
          const apiData = { id: videoId, status: 1 };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) fetchVideoDetail();
            setOpenConf(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete content",
        description: `Are you sure you want to delete ${detail?.caption} ?`,
        clickYes: () => {
          const apiData = { id: videoId, status: 3 };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) {
              setOpenConf(false);
              history.push("/video-feed");
            }
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Flag content",
        description: `Are you sure you want to flag ${detail?.caption} ?`,
        clickYes: () => {
          const apiData = { id: videoId, status: 2 };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) fetchVideoDetail();
            setOpenConf(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpenConf(true);
  };

  const contentType = detail?.contentFormat ? detail?.contentFormat : "mp4";

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section video-detail">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="video-feed-header">
              <VideoBreadCrumb />
              {showAction(alPermissions, 6) && (
                <div className="btn-group">
                  <Button
                    variant="outlined"
                    color={detail?.adminStatus === 1 ? "error" : "success"}
                    onClick={() =>
                      handleClickOpen({
                        type: detail?.adminStatus === 1 ? "flag" : "unflag",
                      })
                    }
                  >
                    {detail?.adminStatus === 1 ? "Flag" : "Unflag"} post
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleClickOpen({ type: "delete" })}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
            <div className="video-section">
              <div className="video-wrapper">
                {/* <div className="zoom-icon">
                  <IconButton aria-label="Example">
                    <CenterFocusWeakIcon />
                  </IconButton>
                </div> */}

                <figure>
                  {detail?.contentUrl && detail?.contentUrl.length > 0 && (
                    <video controls poster={detail?.contentCoverImage}>
                      <source
                        src={detail?.contentUrl}
                        type={`video/${contentType}`}
                      />
                    </video>
                  )}
                </figure>
              </div>
              <div className="video-description">
                <p>{detail?.caption}</p>
              </div>
            </div>
            <div className="video-info-section">
              <div className="flex-row">
                <div className="flex-col">
                  <label>
                    Posted on:{" "}
                    {
                      <Moment format="Do MMM YYYY h:mm A">
                        {detail?.createdAt}
                      </Moment>
                    }
                  </label>
                </div>
                <div className="flex-col">
                  <label>
                    Flagged Status: {detail?.adminStatus === 1 ? "No" : "Yes"}
                  </label>
                </div>
                <div className="flex-col">
                  <label>Fivestar score: {detail?.score}</label>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-col">
                  <label>Sport: {detail?.sport?.sportName}</label>
                </div>
                <div className="flex-col">
                  <label>
                    {detail?.userData?.userType === 1 ? "Fan" : "Athlete"} Name:{" "}
                    <Link
                      to={`/${
                        detail?.userData?.userType === 1 ? "fans" : "athletes"
                      }/${detail?.userData?.userId}`}
                    >
                      {detail?.userData?.name}
                    </Link>
                  </label>
                </div>
              </div>
            </div>
            <div className="tabs-section">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabvalue}>
                  <Box>
                    <TabList
                      onChange={handleChangeTabs}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label={`Rating by (${
                          detail?.rating_total_count ?? 0
                        } Users)`}
                        value="1"
                      />
                      <Tab
                        label={`Tagged users (${detail?.tag_total_count ?? 0})`}
                        value="2"
                      />
                      <Tab
                        label={`Reported by (${
                          detail?.report_total_count ?? 0
                        } Users)`}
                        value="3"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <RankedTabSection contentId={videoId} />
                  </TabPanel>
                  <TabPanel value="2">
                    <TaggedTabSection
                      contentId={videoId}
                      fetchVideoDetail={fetchVideoDetail}
                    />
                  </TabPanel>
                  <TabPanel value="3">
                    <ReportedTabSection contentId={videoId} />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={openConf}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.videoFeedInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getVideoDetail: (data) => dispatch(getVideoDetail(data)),
  flagUnflagDeleteContent: (data) => dispatch(flagUnflagDeleteContent(data)),
  getVideoDetail_: (data) => dispatch(getVideoDetail_(data)),
});
export default withRouter(connect(mapState, mapDispatch)(VideoDetail));
