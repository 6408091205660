import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { getAllStaticContent, deleteFaq } from "store/actions";
import { convertObjToStringParams, showAction } from "utils/helper";
import { Loader } from "components/atoms";
import { Confirmation } from "components/molecules";

const FaqTab = (props) => {
  const { getAllStaticContent, alPermissions, showLoader, deleteFaq } = props;
  const [listData, setListData] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {},
  });

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { type: 2 };
    const params = convertObjToStringParams(apiData);
    getAllStaticContent({ params }).then((resp) => {
      setListData(resp);
    });
  };

  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete faq",
        description: `Are you sure you want to delete this faq ?`,
        clickYes: () => {
          const apiData = {
            id: row.faqQuestions._id,
          };
          deleteFaq(apiData).then((res) => {
            if (res) {
              fetchDetail();
              setOpen(false);
            }
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  return (
    <div className="faq-wrapper">
      {showLoader && <Loader />}
      <div className="cms-content">
        <div className="cms-header">
          <h2>FAQ</h2>
          {showAction(alPermissions, 4) && (
            <Tooltip title="Add">
              <Link to="/static-content/faq-add">Add</Link>
            </Tooltip>
          )}
        </div>
        <div className="content-description">
          {listData &&
            listData.map((faq, i) => {
              return (
                <div className="faq-content" key={faq._id + i}>
                  <div className="content-header">
                    <h3>
                      {faq.faqQuestions.question &&
                        parse(faq.faqQuestions.question)}
                    </h3>
                    {showAction(alPermissions, 4) && (
                      <div className="action-wrap">
                        <Tooltip title="Edit">
                          <Link
                            to={`/static-content/${faq.faqQuestions._id}/faq-edit`}
                          >
                            <IconButton color="primary">
                              <EditIcon />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            color="error"
                            onClick={() =>
                              handleClickOpen({ type: "delete", row: faq })
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className="content-main">
                    {faq.faqQuestions.answer && parse(faq.faqQuestions.answer)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getAllStaticContent: (data) => dispatch(getAllStaticContent(data)),
  deleteFaq: (data) => dispatch(deleteFaq(data)),
});

export default connect(mapState, mapDispatch)(FaqTab);
