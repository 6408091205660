import { callApiAdvanced } from "utils/apiCaller";
import { toast_, loader_ } from "store/actions";
import { GET_FAN_DETAIL } from "store/types";
import { modulesArr } from "utils/helper";

const fanMod = modulesArr.filter((item) => item.id === 2)[0];

const customHeaders = {
  moduleHeader: fanMod.value,
};

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const getAllFans = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/users?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const blockUnblockFan = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/user-status`,
      method: "PATCH",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const deleteFan = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/user?${data.params}`,
      method: "DELETE",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getFanDetail_ = (payload) => {
  return {
    type: GET_FAN_DETAIL,
    payload,
  };
};

export const getFanDetail = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/user?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          dispatch(getFanDetail_(resData?.data));
          return true;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
