import * as React from "react";
import IconButton from "@mui/material/IconButton";
import {
  LeftSidebar,
  HeaderSection,
  PaginationSection,
  AthletesTableList,
} from "components/molecules";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FilterSubmit, FilterReset, Loader } from "components/atoms";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./index.css";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { getAllAthletes } from "store/actions";
import {
  convertObjToStringParams,
  showSection,
  showAction,
} from "utils/helper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ratingValidator } from "utils/validations";

const AthletesList = (props) => {
  const { getAllAthletes, showLoader, alPermissions } = props;
  const [listData, setListData] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    from: "",
    to: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "addedOn", val: -1 },
    location: "",
    rating: "",
    subscriptionType: "",
    status: "",
    error: "",
    ratingError:""
  });

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 1))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Debouncing For Search
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;

    if (key === "rating") {
      newFilter.ratingError = ratingValidator(e.target.value);
    }

    if (newFilter.from && newFilter.to && ["from", "to"].includes(key)) {
      if (newFilter.from > newFilter.to) {
        newFilter.error = "Please select valid age options !";
      } else {
        newFilter.error = "";
      }
    }
    setFilter(newFilter);
  };

  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      sortKey: filter.sortBy.key,
      sortOrder: filter.sortBy.val,
      userType: 2, // Athlete
    };
    if (filter.from) apiData.from = filter.from;
    if (filter.to) apiData.to = filter.to;
    if (filter.search) apiData.search = filter.search;
    if (filter.location) apiData.location = filter.location;
    if (filter.rating) apiData.rating = filter.rating;
    if (filter.subscriptionType) apiData.subscription = filter.subscriptionType;
    if (filter.status) apiData.status = filter.status;
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error || filter.ratingError) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    setAnchorEl(null);
    getAllAthletes({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  const handleFilterReset = () => {
    const newFilter = { ...filter };
    newFilter.from = "";
    newFilter.to = "";
    newFilter.location = "";
    newFilter.rating = "";
    newFilter.subscriptionType = "";
    newFilter.status = "";
    newFilter.error = "";
    newFilter.ratingError = "";
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const renderAgeOptions = (key) => {
    let options = [];
    for (let i = 13; i <= 100; i++) {
      let item = (
        <MenuItem value={i} key={i + key}>
          {i} Years
        </MenuItem>
      );
      options.push(item);
    }
    return options;
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section athlete-list">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Athlete Management</h1>
              </div>
              <div className="breadcrumb-right">
                {showAction(alPermissions, 1) && (
                  <Link to="/athletes/add">
                    <Button variant="contained">Add</Button>
                  </Link>
                )}
              </div>
            </div>
            <div className="search-filter-wrap">
              <div className="serach-wrap">
                <TextField
                  label="Search"
                  variant="outlined"
                  placeholder="Search by name, email and mobile number"
                  onChange={handleSearch}
                  value={filter?.search}
                />
              </div>
              <div className="filter-btn">
                <Tooltip title="Filter">
                  <IconButton
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    color="primary"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <div className="filterPopup">
                    <div className="age-group">
                      <label className="form-label">Age group</label>
                      <div className="filter-row">
                        <div className="filter-col bottom-spacing">
                          <FormControl fullWidth>
                            <InputLabel>From</InputLabel>
                            <Select
                              label="From"
                              placeholder="From"
                              value={filter?.from}
                              onChange={handleChangeFilter("from")}
                            >
                              {renderAgeOptions("from")}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="filter-col">
                          <FormControl fullWidth>
                            <InputLabel>To</InputLabel>
                            <Select
                              label="To"
                              placeholder="To"
                              value={filter?.to}
                              onChange={handleChangeFilter("to")}
                            >
                              {renderAgeOptions("to")}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {filter?.error && (
                        <span className="error-msg">{filter.error}</span>
                      )}
                    </div>
                    <div className="filter-text">
                      <label className="form-label">Location</label>
                      <TextField
                        label="Select"
                        type="number"
                        variant="outlined"
                        value={filter?.location}
                        onChange={handleChangeFilter("location")}
                        placeholder="Enter Zipcode"
                      />
                    </div>
                    <div className="filter-text">
                      <label className="form-label">Rating</label>
                      <TextField
                        type="number"
                        placeholder="Enter Rating"
                        label="Select Rating"
                        variant="outlined"
                        value={filter?.rating}
                        onChange={handleChangeFilter("rating")}
                        className={filter.ratingError ? "input-error" : ""}
                      />
                      {filter.ratingError && (
                        <span className="error-msg">{filter.ratingError}</span>
                      )}
                    </div>
                    <div className="filter-text">
                      <label className="form-label">Subscription Type</label>
                      <FormControl fullWidth>
                        <InputLabel>Subscription Type</InputLabel>
                        <Select
                          label="Subscription Type"
                          placeholder="Select"
                          value={filter?.subscriptionType}
                          onChange={handleChangeFilter("subscriptionType")}
                        >
                          <MenuItem value="3">Non Premium User</MenuItem>
                          <MenuItem value="1">Monthly</MenuItem>
                          <MenuItem value="2">Yearly</MenuItem>
                          {/* <MenuItem value="4">Free trial</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="filter-text">
                      <label className="form-label">Status</label>
                      <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          label="Status"
                          placeholder="Select"
                          value={filter?.status}
                          onChange={handleChangeFilter("status")}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={2}>Blocked</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="btn-wrap">
                      <FilterReset
                        name="Reset"
                        onClickButton={handleFilterReset}
                      />
                      <FilterSubmit
                        name="Apply Filter"
                        onClickButton={handleFilterSubmit}
                      />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            {showLoader && <Loader />}
            <AthletesTableList
              listData={listData}
              filter={filter}
              toggleSortOrder={toggleSortOrder}
              handleFilterSubmit={handleFilterSubmit}
            />
            {listData?.result?.length > 0 && (
              <PaginationSection
                filter={filter}
                listData={listData}
                handleChangePage={handleChangePage}
                handleChangePageLimit={handleChangePageLimit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getAllAthletes: (data) => dispatch(getAllAthletes(data)),
});

AthletesList.propTypes = {
  getAllAthletes: PropTypes.func,
  showLoader: PropTypes.bool,
};
export default connect(mapState, mapDispatch)(AthletesList);
