import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { connect } from "react-redux";
import { updateSingleReportStatus } from "store/actions";

const ModerationTableList = (props) => {
  let { listData } = props;
  let rows =
    listData?.moderationData?.ModerationLabels &&
    listData?.moderationData?.ModerationLabels?.length > 0
      ? listData?.moderationData?.ModerationLabels
      : [];

  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        <figure>
          <FindInPageIcon />
        </figure>
        <h3>
          {listData?.moderationData?.ModerationLabels === undefined
            ? "Loading ..."
            : "No Data Found"}
        </h3>
      </div>
    );
  }

  console.log(listData?.moderationData?.ModerationLabels)
  return (
    <div className="reported-by-tab table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Parent Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row?.ModerationLabel?.Name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.ModerationLabel?.ParentName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});

const mapDispatch = (dispatch) => ({
  updateSingleReportStatus: (data) => dispatch(updateSingleReportStatus(data)),
});
export default connect(mapState, mapDispatch)(ModerationTableList);
