import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory } from "react-router-dom";

const AthleteVideoBreadCrumb = () => {
  const history = useHistory();

  return (
    <div className="title-left">
      <ArrowBackIosNewIcon onClick={() => history.go(-3)} />
      <h1 className="title">Athlete Details</h1>
      <ArrowBackIosNewIcon onClick={() => history.go(-2)} />
      <h2 className="section-sub-title">Video Library</h2>
      <ArrowBackIosNewIcon onClick={() => history.goBack()} />
      <h2 className="section-sub-title">Video Details</h2>
    </div>
  );
};

export default AthleteVideoBreadCrumb;
