import React, { useState } from "react";
import { ModerationTableList } from "components/molecules";
import { Loader } from "components/atoms";
import { getModerationKeys } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { connect } from "react-redux";
import "./index.css";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";

const ModerationSection = (props) => {
  const { getModerationKeys, showLoader, contentId } = props;
  const [listData, setListData] = useState(null);

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    error: "",
  });

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getApiData = () => {
    let apiData = {
      id: contentId,
    };
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getModerationKeys({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  const handleClick = () => {
    setCounter(counter + 1);
  };

  return (
    <div className="reported-wrap">
      {showLoader && <Loader />}
      {(listData === undefined ||
        listData?.moderationData?.JobStatus === 2) && (
        <div className="moderator-loader">
          <label className="moderator-label">Moderation in Progress</label>
          <div className="loader-btn">
            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={handleClick}
            >
              <ReplayIcon />
            </IconButton>
          </div>
        </div>
      )}
      {listData !== undefined && listData?.moderationData?.JobStatus !== 2 && (
        <>
          <div className="report-info-section">
            <div className="flex-row">
              <div className="flex-col">
                <label>
                  Color Range:
                  {listData?.moderationData?.VideoMetadata?.ColorRange}
                </label>
              </div>
              <div className="flex-col">
                <label>
                  Duration Millis:
                  {listData?.moderationData?.VideoMetadata?.DurationMillis}
                </label>
              </div>
              <div className="flex-col">
                <label>
                  Status:
                  {listData?.moderationData?.JobStatus === 1
                    ? "Succeeded"
                    : listData?.moderationData?.JobStatus === 2
                    ? "In Progress"
                    : listData?.moderationData?.JobStatus}
                </label>
              </div>
            </div>
            <div className="flex-row">
              <div className="flex-col">
                <label>
                  Frame Height:
                  {listData?.moderationData?.VideoMetadata?.FrameHeight}
                </label>
              </div>
              <div className="flex-col">
                <label>
                  Frame Rate:
                  {listData?.moderationData?.VideoMetadata?.FrameRate}
                </label>
              </div>
              <div className="flex-col">
                <label>
                  Frame Width:
                  {listData?.moderationData?.VideoMetadata?.FrameWidth}
                </label>
              </div>
            </div>
          </div>
          <ModerationTableList listData={listData} />
        </>
      )}
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
});

const mapDispatch = (dispatch) => ({
  getModerationKeys: (data) => dispatch(getModerationKeys(data)),
});

export default connect(mapState, mapDispatch)(ModerationSection);
