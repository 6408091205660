import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import SportsBlack from "assets/images/sports-black.svg";
import "./index.css";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { ColumnWithSort } from "components/atoms";
import Moment from "react-moment";
import { Confirmation } from "components/molecules";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  convertObjToStringParams,
  getSubOrdinate,
  showAction,
} from "utils/helper";
import { deleteSport } from "store/actions";

const SportsTableList = (props) => {
  const {
    listData,
    filter,
    toggleSortOrder,
    handleFilterSubmit,
    deleteSport,
    alPermissions,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];
  const [open, setOpen] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {},
  });

  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete sport",
        description: `Are you sure you want to delete ${row.sportName} ?`,
        clickYes: () => {
          const apiData = getApiData(row);
          const params = convertObjToStringParams(apiData);
          deleteSport({ params }).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  const getApiData = (row) => {
    return {
      id: row._id,
    };
  };
  const showDefaultImage = (e) => {
    e.target.src = SportsBlack;
  };

  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        <figure>
          <FindInPageIcon />
        </figure>
        <h3>
          {listData?.result === undefined ? "Loading ..." : "No Data Found"}
        </h3>
      </div>
    );
  }
  return (
    <div className="table-wrapper sport-list">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sport Icon</TableCell>
              <TableCell>Sport Name</TableCell>
              <TableCell>Subordinate Type</TableCell>
              <TableCell>Number of Subordinates</TableCell>
              <TableCell>
                <ColumnWithSort
                  title="Last updated on"
                  sortBy={filter?.sortBy}
                  currentKey="updatedOn"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>
              {showAction(alPermissions, 10) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <div className="sport-icon bg">
                    <figure>
                      <img
                        src={row.sportImg}
                        alt="Sport Icon"
                        onError={showDefaultImage}
                      />
                    </figure>
                  </div>
                  {/* <Checkbox /> */}
                </TableCell>
                <TableCell>
                  <Link to={`/sports/${row._id}`}>{row.sportName}</Link>
                </TableCell>
                <TableCell>{getSubOrdinate(row?.ordinateType || 1)}</TableCell>
                <TableCell>{row.positions.length}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY">{row.updatedAt}</Moment>
                </TableCell>
                {showAction(alPermissions, 10) && (
                  <TableCell>
                    <div className="action-wrap">
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

SportsTableList.propTypes = {
  listData: PropTypes.any,
  filter: PropTypes.object,
  toggleSortOrder: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  deleteSport: PropTypes.func,
};
const mapDispatch = (dispatch) => ({
  deleteSport: (data) => dispatch(deleteSport(data)),
});

export default connect(null, mapDispatch)(SportsTableList);
