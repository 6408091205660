import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  NotificationAddTableList,
  PaginationSection,
} from "components/molecules";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import uplodIcon from "assets/images/uploadIcon.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Popover from "@mui/material/Popover";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { FilterSubmit, FilterReset, Loader } from "components/atoms";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import { addNotification, uploadAFile, getAllAthletes } from "store/actions";
import { connect } from "react-redux";
import {
  fileFieldValidator,
  customTextFieldValidator,
  urlValidator,
} from "utils/validations";
import {
  convertObjToStringParams,
  showAction,
  showSection,
} from "utils/helper";

const AddNotification = (props) => {
  const {
    addNotification,
    showLoader,
    uploadAFile,
    alPermissions,
    getAllAthletes,
  } = props;
  let history = useHistory();

  const [formData, setFormData] = React.useState({
    title: "",
    url: "",
    receiverType: 1,
    image: null,
    uploadedFileUrl: "",
    errors: {},
  });

  const [value, setValue] = React.useState([null, null]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    from: "",
    to: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "addedOn", val: -1 },
    status: "",
    error: "",
  });
  const [listData, setListData] = React.useState(null);
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 8))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  ////////////////// Users List ///////////////////////
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;

    setFilter(newFilter);
  };

  //Debouncing For Search
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      sortKey: filter.sortBy.key,
      sortOrder: filter.sortBy.val,
    };
    if (value[0]) apiData.from = value[0];
    if (value[1]) apiData.to = value[1];
    if (filter.search) apiData.search = filter.search;
    if (filter.status) apiData.status = filter.status;
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    setAnchorEl(null);
    getAllAthletes({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  const handleFilterReset = () => {
    const newFilter = { ...filter };
    newFilter.from = "";
    newFilter.to = "";
    newFilter.status = "";
    newFilter.error = "";
    setValue([null, null]);
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  ////////////////// Notification Form /////////////////

  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "title") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 3 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter title !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            false,
            "Please enter only alphabets with spaces in between !",
          ],
          customRegex: [
            true,
            "Please enter only alphabets, &, -, spaces in between !",
            /^[a-z-& ]+$/i,
          ],
        });
      }
      if (key === "url") {
        err = urlValidator({
          val,
          req: [true, "Please enter url !"],
          msg: "Please enter a valid URL !",
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.title = customTextFieldValidator({
        min: [3, "Min. length is 3 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.title,
        req: [true, "Please enter title !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          false,
          "Please enter only alphabets with spaces in between !",
        ],
        customRegex: [
          true,
          "Please enter only alphabets, &, -, spaces in between !",
          /^[a-z-& ]+$/i,
        ],
      });

      newFormState.errors.url = urlValidator({
        val: newFormState.url,
        req: [true, "Please enter url !"],
        msg: "Please enter a valid URL !",
      });

      newFormState.errors.uploadedFileUrl = fileFieldValidator({
        min: 1,
        max: 800,
        fileType: "image/png",
        fileSizeUnit: "kB",
        multiplier: 1000,
        file: newFormState.image,
        req: true,
      });

      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (checkForError()) return false;
    const data = new FormData();
    data.append("files", formData.image);
    uploadAFile(data).then((res) => {
      if (res) {
        let postData = {
          image: res.fileUrl,
          title: formData.title.trim(),
          url: formData.url.trim(),
          receiverType: parseInt(formData.receiverType),
        };
        if (selectedUsers.length > 0) {
          postData.receiverIds = selectedUsers;
        }
        addNotification(postData).then((resp) => {
          if (resp) {
            onCloseForm();
            history.push("/notifications");
          }
        });
      }
    });
  };

  const onCloseForm = () => {
    setFormData({
      title: "",
      url: "",
      receiverType: 1,
      image: null,
      uploadedFileUrl: "",
      errors: {},
    });
  };

  const onChangeFile = (e) => {
    const newFormData = { ...formData };

    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    let uploadedFileUrl = "";
    let uploadedFile = e.target.files[0];

    if (uploadedFile) {
      uploadedFileUrl = URL.createObjectURL(e.target.files[0]);
      newFormData.image = e.target.files[0];
      newFormData.uploadedFileUrl = uploadedFileUrl;

      newFormData.errors.uploadedFileUrl = fileFieldValidator({
        min: 1,
        max: 800,
        fileType: "image/png",
        fileSizeUnit: "kB",
        multiplier: 1000,
        file: newFormData.image,
        req: true,
      });
    } else {
      newFormData.image = null;
      newFormData.uploadedFileUrl = "";
      newFormData.errors.uploadedFileUrl = "Please upload a file !";
    }
    setFormData(newFormData);
  };

  const handleRemoveImage = () => {
    const newFormData = { ...formData };
    newFormData.image = null;
    newFormData.uploadedFileUrl = "";
    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    setFormData(newFormData);
  };

  const handleCorruptImage = () => {
    const newFormData = { ...formData };
    newFormData.image = null;
    newFormData.uploadedFileUrl = "";
    newFormData.errors.uploadedFileUrl = "Please upload a valid file format !";

    setFormData(newFormData);
  };

  const handleChange = (event) => {
    const newFormData = { ...formData };
    newFormData.receiverType = event.target.value;
    setFormData(newFormData);
    if (parseInt(event.target.value) === 8) {
      setCounter((pc) => pc + 1);
    }
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section add-athlete">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h1 className="title">Add Notification</h1>
              </div>
              {showAction(alPermissions, 8) && (
                <div className="breadcrumb-right">
                  <Button variant="contained" onClick={handleSubmit}>
                    Send
                  </Button>
                </div>
              )}
            </div>
            <div className="add-notification-wrap">
              <div className="form-row">
                <div className="col-half">
                  <div className="notification-text">
                    <label className="form-label">Title*</label>
                    <TextField
                      placeholder="Type title"
                      variant="outlined"
                      onChange={onChangeForm("title")}
                      value={formData.title}
                    />
                  </div>
                  {formData?.errors?.title && (
                    <span className="error-msg">{formData?.errors?.title}</span>
                  )}
                </div>
                <div className="col-half">
                  <div className="notification-text">
                    <label className="form-label">URL*</label>
                    <TextField
                      placeholder="Type URL"
                      variant="outlined"
                      onChange={onChangeForm("url")}
                      value={formData.url}
                    />
                  </div>
                  {formData?.errors?.url && (
                    <span className="error-msg">{formData?.errors?.url}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="notification-info">
              <div className="notification-description">
                <div className="upload-wrap">
                  {(!formData.uploadedFileUrl ||
                    formData?.errors?.uploadedFileUrl) && (
                    <div className="notification-upload-file">
                      <input
                        type="file"
                        name="sportImage"
                        onChange={onChangeFile}
                        accept=".png"
                      />
                      <div className="upload-icon">
                        <img src={uplodIcon} alt="Upload Icon" />
                        <p>Upload Image</p>
                      </div>
                    </div>
                  )}
                  {formData.uploadedFileUrl &&
                    !formData?.errors?.uploadedFileUrl && (
                      <div className="upload-image">
                        <figure>
                          <img
                            src={formData.uploadedFileUrl}
                            alt="User Icon"
                            onError={handleCorruptImage}
                          />
                        </figure>
                        <div className="close-icon" onClick={handleRemoveImage}>
                          <CloseIcon />
                        </div>
                      </div>
                    )}
                </div>
                {formData?.errors?.uploadedFileUrl && (
                  <span className="error-msg">
                    {formData?.errors?.uploadedFileUrl}
                  </span>
                )}
              </div>

              <p className="note">
                Maximum size allowed: 800 kB, Format supported: PNG only
              </p>
              {parseInt(formData.receiverType) === 8 &&
                selectedUsers.length === 0 && (
                  <span className="error-msg">
                    Please select atleast one user from below !
                  </span>
                )}
            </div>
            {/* <div className="form-row" hidden>
              <div className="col-half">
                <div className="notification-text" hidden>
                  <label className="form-label">URL*</label>
                  <TextField
                    placeholder="Paste URL"
                    variant="outlined"
                    multiline
                    rows={4}
                  />
                </div>
              </div>
            </div> */}
            <div className="form-row spacing">
              <div className="col-half">
                <label className="form-label top-spacing">Recipient*</label>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formData.receiverType}
                  onChange={handleChange}
                >
                  <div className="btn-row">
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="All Users"
                    />
                    <FormControlLabel
                      value={8}
                      control={<Radio />}
                      label="Selected Users"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Fan"
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Athlete"
                    />
                  </div>
                  <div className="btn-row">
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="Fan with basic plan"
                    />
                    <FormControlLabel
                      value={5}
                      control={<Radio />}
                      label="Athlete with basic plan"
                    />
                    <FormControlLabel
                      value={6}
                      control={<Radio />}
                      label="iOS"
                    />
                    <FormControlLabel
                      value={7}
                      control={<Radio />}
                      label="Andriod"
                    />
                  </div>
                  {/* <div className="other-field">
                    <div className="radio-btn-field">
                      <FormControlLabel
                        value={9}
                        control={<Radio />}
                        label="Other"
                      />
                    </div>
                    <div className="search-field">
                      <TextField
                        id="standard-basic"
                        label="Email"
                        variant="outlined"
                        placeholder="Enter user's email address"
                      />
                    </div>
                  </div> */}
                </RadioGroup>
              </div>
            </div>
            {parseInt(formData.receiverType) === 8 && (
              <>
                <div className="search-filter-wrap">
                  <div className="serach-wrap">
                    <TextField
                      label="Search"
                      variant="outlined"
                      placeholder="Search by name, email and mobile number"
                      onChange={handleSearch}
                      value={filter?.search}
                    />
                  </div>
                  <div className="filter-btn">
                    <Tooltip title="Filter">
                      <IconButton
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        color="primary"
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <div className="filterPopup">
                        <label className="form-label">Added Date</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateRangePicker
                            startText="From"
                            endText="To"
                            value={value}
                            open={datePickerOpen}
                            onChange={(newValue) => {
                              setValue(newValue);
                              if (
                                newValue[0] !== null &&
                                newValue[1] !== null
                              ) {
                                setDatePickerOpen(false);
                              }
                            }}
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField
                                  onClick={() => {
                                    setDatePickerOpen(true);
                                  }}
                                  {...startProps}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField
                                  onClick={() => {
                                    setDatePickerOpen(true);
                                  }}
                                  {...endProps}
                                />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                        <div className="filter-text">
                          <label className="form-label">Status</label>
                          <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                              label="Status"
                              placeholder="Status"
                              value={filter?.status}
                              onChange={handleChangeFilter("status")}
                            >
                              <MenuItem value={1}>Active</MenuItem>
                              <MenuItem value={2}>Blocked</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="btn-wrap">
                          <FilterReset
                            name="Reset"
                            onClickButton={handleFilterReset}
                          />
                          <FilterSubmit
                            name="Apply Filter"
                            onClickButton={handleFilterSubmit}
                          />
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
                <NotificationAddTableList
                  listData={listData}
                  filter={filter}
                  toggleSortOrder={toggleSortOrder}
                  handleFilterSubmit={handleFilterSubmit}
                  alPermissions={alPermissions}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                />
                {listData?.result?.length > 0 && (
                  <PaginationSection
                    filter={filter}
                    listData={listData}
                    handleChangePage={handleChangePage}
                    handleChangePageLimit={handleChangePageLimit}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  addNotification: (data) => dispatch(addNotification(data)),
  uploadAFile: (data) => dispatch(uploadAFile(data)),
  getAllAthletes: (data) => dispatch(getAllAthletes(data)),
});
export default connect(mapState, mapDispatch)(AddNotification);
