import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FlagIcon from "@mui/icons-material/Flag";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import "./index.css";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Confirmation } from "components/molecules";
import { flagUnflagDeleteContent, updateReportStatus } from "store/actions";
import { connect } from "react-redux";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { showAction } from "utils/helper";

const PostsTableList = (props) => {
  const {
    listData,
    flagUnflagDeleteContent,
    handleFilterSubmit,
    updateReportStatus,
    alPermissions,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData.result : [];
  const [open, setOpen] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {},
  });

  // const startVal =
  //   listData?.page && listData.page !== 1
  //     ? (listData?.page - 1) * filter.pageLimit
  //     : 0;

  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };

    if (type === "mark-pending") {
      obj = {
        ...obj,
        title: "Mark as pending",
        description: `Are you sure you want to mark report as pending ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            reportStatus: 1,
            reportType: 2,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "mark-resolved") {
      obj = {
        ...obj,
        title: "Mark as resolved",
        description: `Are you sure you want to mark report as resolved ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            reportStatus: 2,
            reportType: 2,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "flag") {
      obj = {
        ...obj,
        title: "Flag content",
        description: `Are you sure you want to flag ${row.caption} ?`,
        clickYes: () => {
          const apiData = {
            id: row.content_id,
            status: 2,
          };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete content",
        description: `Are you sure you want to delete ${row.caption} ?`,
        clickYes: () => {
          const apiData = {
            id: row.content_id,
            status: 3,
          };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Unflag content",
        description: `Are you sure you want to unflag ${row.caption} ?`,
        clickYes: () => {
          const apiData = {
            id: row.content_id,
            status: 1,
          };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };
  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        <figure>
          <FindInPageIcon />
        </figure>
        <h3>
          {listData?.result === undefined ? "Loading ..." : "No Data Found"}
        </h3>
      </div>
    );
  }
  return (
    <div className="post-tab table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Uploaded By</TableCell>
              <TableCell>Caption</TableCell>
              <TableCell>Report Count</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Moderation Status</TableCell>
              {showAction(alPermissions, 7) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.uploadedBy.name}</TableCell>
                <TableCell>
                  <Tooltip title={row.caption ? row.caption : "NA"}>
                    <Link to={`/video-feed/${row._id}`}>
                      {row.caption.length <= 10 && row.caption}
                      {row.caption.length > 10 &&
                        `${row.caption.slice(0, 10)} ...`}
                      {!row.caption && "NA"}
                    </Link>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {row.reportCount} User
                  {[0, 1].includes(row.reportCount) ? "" : "s"}
                </TableCell>
                <TableCell
                  className={`status-${
                    row.reportStatus === 1 ? "block" : "unblock"
                  }`}
                >
                  {row.reportStatus === 1 ? "Pending" : "Resolved"}
                  {/* Resolved */}
                </TableCell>
                <TableCell>
                  {row.moderationStatus === 1
                    ? "Succeeded"
                    : row.moderationStatus === 2
                    ? "In Progress"
                    : row.moderationStatus}
                </TableCell>
                {showAction(alPermissions, 7) && (
                  <TableCell>
                    <div className="action-wrap">
                      <Tooltip
                        title={`Mark ${
                          row.reportStatus === 1 ? "Resolved" : "Pending"
                        }`}
                      >
                        <IconButton
                          onClick={() =>
                            handleClickOpen({
                              type: `mark-${
                                row.reportStatus === 1 ? "resolved" : "pending"
                              }`,
                              row,
                            })
                          }
                        >
                          <AssignmentTurnedInIcon
                            color={row.reportStatus === 1 ? "success" : "error"}
                          />
                        </IconButton>
                      </Tooltip>
                      {row.adminStatus === 1 ? (
                        <Tooltip title="Flag content">
                          <IconButton
                            onClick={() =>
                              handleClickOpen({ type: "flag", row })
                            }
                          >
                            <FlagIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Unflag content">
                          <IconButton
                            onClick={() =>
                              handleClickOpen({ type: "unflag", row })
                            }
                          >
                            <FlagIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Detail">
                        <Link
                          to={`/reported-content/post/${row.content_id}?reportId=${row._id}&reportStatus=${row.reportStatus}`}
                        >
                          <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="span"
                          >
                            <RemoveRedEyeOutlinedIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});

const mapDispatch = (dispatch) => ({
  flagUnflagDeleteContent: (data) => dispatch(flagUnflagDeleteContent(data)),
  updateReportStatus: (data) => dispatch(updateReportStatus(data)),
});

export default connect(mapState, mapDispatch)(PostsTableList);
