import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { AccountLeftSection } from "components/molecules";
import "./index.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { passwordFieldValidator } from "utils/validations";
import { useParams, withRouter, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetPassword, verifyPasswordToken } from "store/actions";
import { Loader } from "components/atoms";

const ResetPassword = (props) => {
  const { resetPassword, showLoader, verifyPasswordToken } = props;
  const { token } = useParams();
  const history = useHistory();

  const [fieldType, setFieldType] = useState({
    newPassword: "password",
    confirmPassword: "password",
  });

  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
    errors: {},
  });

  useEffect(() => {
    let data = {
      token,
    };
    verifyPasswordToken(data).then((res) => {
      if (!res) {
        history.push("/forgot-password");
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleFieldType = (type) => {
    const fieldTypeData = { ...fieldType };
    if (fieldTypeData[type] === "password") {
      fieldTypeData[type] = "text";
    } else {
      fieldTypeData[type] = "password";
    }
    setFieldType(fieldTypeData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkForError()) return false;
    const data = { token, password: formData.newPassword };
    resetPassword(data).then((res) => {
      if (res) {
        onCloseForm();
        history.push("/");
      }
    });
  };

  const onCloseForm = () => {
    setFormData({
      newPassword: "",
      confirmPassword: "",
      errors: {},
    });
  };

  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const changePasswordErrorMsg = (key) => {
    if (key === "newPassword") {
      return "Please enter new password !";
    } else {
      return "Please enter confirm password !";
    }
  };

  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (
        key === "newPassword" &&
        newFormState.errors?.confirmPassword ===
          "New Password and Confirm Password doesn't match !"
      ) {
        newFormState.errors.confirmPassword = "";
      }
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "newPassword" || key === "confirmPassword") {
        err = passwordFieldValidator(
          8,
          40,
          val,
          true,
          key === "newPassword" ? true : false
        );
        if (err === "Please enter your password !") {
          err = changePasswordErrorMsg(key);
        }
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.newPassword = passwordFieldValidator(
        8,
        40,
        newFormState.newPassword,
        true,
        true
      );
      if (newFormState.errors.newPassword === "Please enter your password !") {
        newFormState.errors.newPassword = changePasswordErrorMsg("newPassword");
      }
      newFormState.errors.confirmPassword = passwordFieldValidator(
        8,
        40,
        newFormState.confirmPassword,
        true,
        false
      );
      if (
        newFormState.errors.confirmPassword === "Please enter your password !"
      ) {
        newFormState.errors.confirmPassword =
          changePasswordErrorMsg("confirmPassword");
      }
      if (
        newFormState.newPassword &&
        newFormState.confirmPassword &&
        newFormState.newPassword !== newFormState.confirmPassword
      ) {
        newFormState.errors.confirmPassword =
          "New Password and Confirm Password doesn't match !";
      }
      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="accountWrapper">
      {showLoader && <Loader />}
      <div className="accountInner">
        <AccountLeftSection />
        <div className="right-section">
          <div className="right-inner">
            <h1 className="page-title">Reset Password</h1>
            <p className="page-description">
              Please make sure you at least have 8 characters
            </p>
            <div className="form-wrapper">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="input-row">
                  <div className="form-group">
                    <TextField
                      type={fieldType.newPassword}
                      id="new-password"
                      label="New Password"
                      variant="outlined"
                      value={formData.newPassword}
                      onChange={onChangeForm("newPassword")}
                      className={
                        formData?.errors?.newPassword ? "input-error" : ""
                      }
                    />

                    <div
                      className="eye-icon"
                      onClick={() => toggleFieldType("newPassword")}
                    >
                      <IconButton aria-label="toggle password visibility">
                        {fieldType.newPassword === "password" ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </div>
                  </div>
                  {formData?.errors?.newPassword && (
                    <span className="error-msg">
                      {formData?.errors?.newPassword}
                    </span>
                  )}
                </div>
                <div className="input-row">
                  <div className="form-group">
                    <TextField
                      type={fieldType.confirmPassword}
                      id="confirm-password"
                      label="Confirm Password"
                      variant="outlined"
                      value={formData.confirmPassword}
                      onChange={onChangeForm("confirmPassword")}
                      className={
                        formData?.errors?.confirmPassword ? "input-error" : ""
                      }
                    />
                    <div
                      className="eye-icon"
                      onClick={() => toggleFieldType("confirmPassword")}
                    >
                      <IconButton aria-label="toggle password visibility">
                        {fieldType.confirmPassword === "password" ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </div>
                  </div>
                  {formData?.errors?.confirmPassword && (
                    <span className="error-msg">
                      {formData?.errors?.confirmPassword}
                    </span>
                  )}
                </div>
                <div className="btn-wrap login-btn">
                  <Button variant="contained" type="submit">
                    Set New Password
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
});

const mapDispatch = (dispatch) => ({
  resetPassword: (data) => dispatch(resetPassword(data)),
  verifyPasswordToken: (data) => dispatch(verifyPasswordToken(data)),
});

ResetPassword.propTypes = {
  resetPassword: PropTypes.func,
  showLoader: PropTypes.bool,
  verifyPasswordToken: PropTypes.func,
};
export default withRouter(connect(mapState, mapDispatch)(ResetPassword));
