import React from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import logo from "assets/images/logo.svg";
import "./index.css";
import { Confirmation } from "components/molecules";
import {
  loginUser_,
  logoutUser,
  setInitialMenu_,
  myDetails,
} from "store/actions";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Tooltip } from "@mui/material";

const HeaderSection = (props) => {
  const { loginUserFn, logoutUser, setInitialMenu_, myDetails } = props;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    myDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickYes = () => {
    setOpen(false);
    setInitialMenu_();
    loginUserFn(null);
    logoutUser().then((res) => {
      if (res) {
        localStorage.clear();
        history.push("/");
      }
    });
  };
  return (
    <>
      <header className="header">
        <div className="header-inner">
          <div className="logo-wrap">
            <Link to="/dashboard">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="user-setting-wrap">
            <div className="user-pic-wrap">
              <ul>
                <li className="user-icon">
                  <Tooltip title="User Profile">
                    <Link to="/profile">
                      <PersonOutlineIcon />
                    </Link>
                  </Tooltip>
                </li>
                <li className="logout-icon">
                  <Tooltip title="Logout">
                    <Button variant="text" onClick={handleClickOpen}>
                      <LogoutIcon />
                    </Button>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <Confirmation
        title="Logout"
        description="Are you sure you want to logout from admin panel?"
        open={open}
        handleClose={handleClose}
        handleClickYes={handleClickYes}
      />
    </>
  );
};

const mapDispatch = (dispatch) => ({
  loginUserFn: (data) => dispatch(loginUser_(data)),
  logoutUser: () => dispatch(logoutUser()),
  setInitialMenu_: () => dispatch(setInitialMenu_()),
  myDetails: () => dispatch(myDetails()),
});

export default withRouter(connect(null, mapDispatch)(HeaderSection));
