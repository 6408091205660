import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./index.css";
import { passwordFieldValidator } from "utils/validations";

const PasswordInfo = (props) => {
  const { changeMyPassword } = props;

  const [fieldType, setFieldType] = React.useState({
    currentPassword: "password",
    newPassword: "password",
    confirmPassword: "password",
  });

  const [formData, setFormData] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    errors: {},
  });

  const toggleFieldType = (type) => {
    const fieldTypeData = { ...fieldType };
    if (fieldTypeData[type] === "password") {
      fieldTypeData[type] = "text";
    } else {
      fieldTypeData[type] = "password";
    }
    setFieldType(fieldTypeData);
  };

  const onCloseForm = () => {
    setFormData({
      newPassword: "",
      confirmPassword: "",
      currentPassword: "",
      errors: {},
    });
  };

  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key.includes("word")) {
        err = passwordFieldValidator(8, 40, val, true);
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.currentPassword = passwordFieldValidator(
        8,
        40,
        newFormState.currentPassword,
        true,
        false
      );
      newFormState.errors.newPassword = passwordFieldValidator(
        8,
        40,
        newFormState.newPassword,
        true,
        true
      );
      newFormState.errors.confirmPassword = passwordFieldValidator(
        8,
        40,
        newFormState.confirmPassword,
        true,
        true
      );
      if (
        newFormState.errors.confirmPassword ||
        newFormState.confirmPassword !== newFormState.newPassword
      ) {
        newFormState.errors.confirmPassword =
          "Please enter same password as new password";
      }
      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (checkForError()) return false;
    const data = {
      oldPassword: formData.currentPassword,
      newPassword: formData.confirmPassword,
    };
    changeMyPassword(data).then((res) => {
      if (res) {
        setFormData({
          newPassword: "",
          currentPassword: "",
          confirmPassword: "",
          errors: {},
        });
      }
    });
  };

  return (
    <div className="password-info">
      <div className="password-title">
        <div className="title-left">
          <h3 className="section-heading">Change Password</h3>
        </div>
        <div className="btn-group">
          <Button variant="outlined" onClick={handleSubmit}>
            Save
          </Button>{" "}
        </div>
      </div>
      <div className="user-password-row">
        <div className="password-col">
          <div className="form-group">
            <TextField
              label="Current Password"
              id="password"
              type={fieldType.currentPassword}
              value={formData.currentPassword}
              onChange={onChangeForm("currentPassword")}
              className={formData?.errors?.currentPassword ? "input-error" : ""}
            />
            <div className="eye-icon">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => toggleFieldType("currentPassword")}
              >
                {fieldType.currentPassword === "password" ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </div>
            {formData?.errors?.currentPassword && (
              <span className="error-msg">
                {formData?.errors?.currentPassword}
              </span>
            )}
          </div>
        </div>
        <div className="password-col">
          <div className="form-group">
            <TextField
              label="New Password"
              // id="password"
              type={fieldType.newPassword}
              value={formData.newPassword}
              onChange={onChangeForm("newPassword")}
              className={formData?.errors?.newPassword ? "input-error" : ""}
            />
            <div className="eye-icon">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => toggleFieldType("newPassword")}
              >
                {fieldType.newPassword === "password" ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </div>
            {formData?.errors?.newPassword && (
              <span className="error-msg">{formData?.errors?.newPassword}</span>
            )}
          </div>
        </div>
        <div className="password-col">
          <div className="form-group">
            <TextField
              label="Confirm Password"
              // id="password"
              type={fieldType.confirmPassword}
              value={formData.confirmPassword}
              onChange={onChangeForm("confirmPassword")}
              className={formData?.errors?.confirmPassword ? "input-error" : ""}
            />
            <div className="eye-icon">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => toggleFieldType("confirmPassword")}
              >
                {fieldType.confirmPassword === "password" ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </div>
            {formData?.errors?.confirmPassword && (
              <span className="error-msg">
                {formData?.errors?.confirmPassword}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordInfo;
