import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  RichTextEditor,
} from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

const AboutUsEdit = () => {
  const history = useHistory();

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          <div className="main-section-inner">
            <div className="static-content-header">
              <div className="title-left">
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h1 className="title">Edit</h1>
              </div>
              <div className="btn-group">
                <Button variant="contained">Save</Button>
              </div>
            </div>
            <div className="text-editor">
              <RichTextEditor />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsEdit;
