import * as React from "react";
import { VideoTaggedTableList, PaginationSection } from "components/molecules";
import TextField from "@mui/material/TextField";
import { FilterSubmit, FilterReset } from "components/atoms";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popover from "@mui/material/Popover";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Loader } from "components/atoms";
import { getTaggedUsers } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { connect } from "react-redux";

const TaggedTabSection = (props) => {
  const { getTaggedUsers, showLoader, contentId, fetchVideoDetail } = props;
  const [listData, setListData] = React.useState(null);

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: 10,
    pageNo: 1,
    ranking: "",
    error: "",
  });
  const [value, setValue] = React.useState([null, null]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      id: contentId,
    };
    if (filter.ranking) apiData.ranking = filter.ranking;
    if (filter.search) apiData.search = filter.search;
    if (value[0]) apiData.from = value[0];
    if (value[1]) apiData.to = value[1];
    return apiData;
  };

  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getTaggedUsers({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  //Debouncing For Search
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;
    setFilter(newFilter);
  };

  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  const handleFilterReset = () => {
    const newFilter = { ...filter };
    newFilter.error = "";
    newFilter.ranking = "";
    setValue([null, null]);
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="taged-wrap">
      {showLoader && <Loader />}
      <div className="search-filter-wrap">
        <div className="serach-wrap">
          <TextField
            label="Search"
            variant="outlined"
            placeholder="Search by name"
            onChange={handleSearch}
            value={filter?.search}
          />
        </div>
        {/* <div className="filter-btn">
          <Tooltip title="Filter">
            <IconButton
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              color="primary"
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <div className="filterPopup">
              <label className="form-label">Created date</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="From"
                  endText="To"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
              <div className="filter-text">
                <label className="form-label">Ranking</label>
                <TextField
                  type="number"
                  label="Select"
                  variant="outlined"
                  onChange={handleChangeFilter("ranking")}
                  value={filter?.ranking}
                />
              </div>
              <div className="btn-wrap">
                <FilterReset name="Reset" onClickButton={handleFilterReset} />
                <FilterSubmit
                  name="Apply Filter"
                  onClickButton={handleFilterSubmit}
                />
              </div>
            </div>
          </Popover>
        </div> */}
      </div>
      <VideoTaggedTableList
        listData={listData}
        filter={filter}
        handleFilterSubmit={handleFilterSubmit}
        fetchVideoDetail={fetchVideoDetail}
      />
      {listData?.result?.length > 0 && (
        <PaginationSection
          filter={filter}
          listData={listData}
          handleChangePage={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
        />
      )}
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getTaggedUsers: (data) => dispatch(getTaggedUsers(data)),
});
export default connect(mapState, mapDispatch)(TaggedTabSection);
