import {
  AthletesRankedTableList,
  PaginationSection,
} from "components/molecules";
import TextField from "@mui/material/TextField";

const AthleteRankedTabSection = () => {
  return (
    <div className="rank-wrap">
      <div className="search-filter-wrap">
        <div className="serach-wrap">
          <TextField
            label="Search"
            variant="outlined"
            placeholder="Search by name, email and mobile number"
          />
        </div>
      </div>
      <AthletesRankedTableList />
      <PaginationSection />
    </div>
  );
};

export default AthleteRankedTabSection;
