import * as React from "react";
import { UnauthorizedPage } from "components/atoms";

const Unauthorized = () => {
  return (
    <div>
      <UnauthorizedPage />
    </div>
  );
};

export default Unauthorized;
