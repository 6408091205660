import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ColumnWithSort = (props) => {
  const { sortBy, title, currentKey, toggleSortOrder } = props;
  return (
    <div className="sort-outer">
      <span>{title}</span>
      <span
        className={`sort-by ${
          sortBy.key !== currentKey ? "sort-disabled" : ""
        }`}
      >
        {sortBy.key === currentKey ? (
          <span className="sort-by-inner">
            {sortBy?.val === -1 ? (
              <ArrowDropUpIcon
                onClick={() => toggleSortOrder({ currentKey, value: 1 })}
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => toggleSortOrder({ currentKey, value: -1 })}
              />
            )}
          </span>
        ) : (
          <span className="sort-by-inner">
            <ArrowDropUpIcon
              onClick={() => toggleSortOrder({ currentKey, value: 1 })}
            />
            <ArrowDropDownIcon
              onClick={() => toggleSortOrder({ currentKey, value: -1 })}
            />
          </span>
        )}
      </span>
    </div>
  );
};

export default ColumnWithSort;
