import * as React from "react";
import { Link, withRouter, useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { LeftSidebar, HeaderSection } from "components/molecules";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { updateSport, uploadAFile, getSportDetail } from "store/actions";
import { connect } from "react-redux";
import {
  fileFieldValidator,
  customTextFieldValidator,
} from "utils/validations";
import { Loader } from "components/atoms";
import "../add-sport/index.css";
import {
  convertObjToStringParams,
  showAction,
  showSection,
} from "utils/helper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const EditSport = (props) => {
  const {
    updateSport,
    showLoader,
    uploadAFile,
    editSport,
    getSportDetail,
    alPermissions,
  } = props;
  const [formData, setFormData] = React.useState({
    name: "",
    ordinateType: 1,
    image: null,
    uploadedFileUrl: "",
    errors: {},
  });
  const history = useHistory();
  const { id } = useParams();

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 10))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchSportDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (editSport && editSport._id) {
      setInitialFormData();
    }
  }, [editSport]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSportDetail = () => {
    const apiData = { id };
    const params = convertObjToStringParams(apiData);
    getSportDetail({ params });
  };

  const setInitialFormData = () => {
    const newFormData = { ...formData };
    newFormData.name = editSport?.sportName;
    newFormData.image = "img-url";
    newFormData.ordinateType = editSport?.ordinateType;
    newFormData.uploadedFileUrl = editSport?.sportImg;
    setFormData(newFormData);
  };

  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "name") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 3 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter name !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            false,
            "Please enter only alphabets with spaces in between !",
          ],
          customRegex: [
            true,
            "Please enter only alphabets, digits, /, &, -, spaces in between !",
            /^[a-z/0-9-& ]+$/i,
          ],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.name = customTextFieldValidator({
        min: [3, "Min. length is 3 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.name,
        req: [true, "Please enter name !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          false,
          "Please enter only alphabets with spaces in between !",
        ],
        customRegex: [
          true,
          "Please enter only alphabets, digits, /, &, -, spaces in between !",
          /^[a-z/0-9-& ]+$/i,
        ],
      });

      if (newFormState.image !== "img-url") {
        newFormState.errors.uploadedFileUrl = fileFieldValidator({
          min: 1,
          max: 100,
          fileType: "image/png",
          fileSizeUnit: "kB",
          multiplier: 1000,
          file: newFormState.image,
          req: true,
        });
      }

      let allKeys = Object.keys(newFormState?.errors);
      if (allKeys.length > 0) {
        allKeys = allKeys.forEach((el) => {
          if (newFormState?.errors[el] === "") delete newFormState?.errors[el];
        });
      }
    }
    setFormData(newFormState);
    if (Object.keys(newFormState.errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (checkForError()) return false;
    if (formData.image !== "img-url") {
      const data = new FormData();
      data.append("files", formData.image);
      uploadAFile(data).then((res) => {
        if (res) {
          let postData = {
            sportImg: res.fileUrl,
            sportName: formData.name.trim(),
            ordinateType: parseInt(formData.ordinateType),
            id: editSport?._id,
          };
          updateSport(postData).then((resp) => {
            if (resp) {
              onCloseForm();
              history.push("/sports");
            }
          });
        }
      });
    } else {
      let postData = {
        sportImg: editSport?.sportImg,
        sportName: formData.name.trim(),
        ordinateType: parseInt(formData.ordinateType),
        id: editSport?._id,
      };
      updateSport(postData).then((resp) => {
        if (resp) {
          onCloseForm();
          history.push("/sports");
        }
      });
    }
  };

  const onCloseForm = () => {
    setFormData({
      name: "",
      image: null,
      ordinateType: 1,
      uploadedFileUrl: "",
      errors: {},
    });
  };

  const onChangeFile = (e) => {
    const newFormData = { ...formData };

    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    let uploadedFileUrl = "";
    let uploadedFile = e.target.files[0];

    if (uploadedFile) {
      uploadedFileUrl = URL.createObjectURL(e.target.files[0]);
      newFormData.image = e.target.files[0];
      newFormData.uploadedFileUrl = uploadedFileUrl;

      newFormData.errors.uploadedFileUrl = fileFieldValidator({
        min: 1,
        max: 100,
        fileType: "image/png",
        fileSizeUnit: "kB",
        multiplier: 1000,
        file: newFormData.image,
        req: true,
      });
    } else {
      newFormData.image = null;
      newFormData.uploadedFileUrl = "";
      newFormData.errors.uploadedFileUrl = "Please upload a file !";
    }
    setFormData(newFormData);
  };

  const handleRemoveImage = () => {
    const newFormData = { ...formData };
    newFormData.image = null;
    newFormData.uploadedFileUrl = "";
    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    setFormData(newFormData);
  };

  const handleCorruptImage = () => {
    const newFormData = { ...formData };
    newFormData.image = null;
    newFormData.uploadedFileUrl = "";
    newFormData.errors.uploadedFileUrl = "Please upload a valid file format !";

    setFormData(newFormData);
  };

  const handleChange = (event) => {
    const newFormData = { ...formData };
    newFormData.ordinateType = event.target.value;
    setFormData(newFormData);
  };

  return (
    <div className="pageWrapper">
      {showLoader && <Loader />}
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          <div className="sport-wrap">
            <div className="sport-title">
              <div className="title-left">
                <Link to="/sports">
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title-info">Edit Sport</h1>
              </div>
              <div className="btn-group">
                {showAction(alPermissions, 10) && (
                  <Button variant="contained" onClick={handleSubmit}>
                    Save
                  </Button>
                )}
              </div>
            </div>
            <div className="sport-info">
              <div className="sport-description">
                <p>Upload a sport icon in PNG</p>
                <div className="upload-wrap">
                  {(!formData.uploadedFileUrl ||
                    formData?.errors?.uploadedFileUrl) && (
                    <div className="upload-file">
                      <input
                        type="file"
                        name="sportImage"
                        onChange={onChangeFile}
                        accept=".png"
                      />
                      <div className="upload-icon">
                        <FileUploadOutlinedIcon />
                      </div>
                    </div>
                  )}
                  {formData.uploadedFileUrl &&
                    !formData?.errors?.uploadedFileUrl && (
                      <div className="upload-image-2">
                        <figure>
                          <img
                            src={formData.uploadedFileUrl}
                            alt="User Icon"
                            onError={handleCorruptImage}
                          />
                        </figure>

                        <div className="close-icon" onClick={handleRemoveImage}>
                          <CloseIcon />
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {formData?.errors?.uploadedFileUrl && (
                <span className="error-msg">
                  {formData?.errors?.uploadedFileUrl}
                </span>
              )}
              <div className="sport-text">
                <label className="sport-label">Sport Name</label>
                <TextField
                  variant="outlined"
                  placeholder="Enter sport name"
                  onChange={onChangeForm("name")}
                  value={formData.name}
                />
              </div>
              {formData?.errors?.name && (
                <span className="error-msg">{formData?.errors?.name}</span>
              )}
              <div className="sport-text">
                <label className="sport-label">Select subordinate type</label>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formData.ordinateType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Positions "
                  />
                  {/* <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Stance"
                  /> */}
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Style"
                  />
                  <FormControlLabel value={4} control={<Radio />} label="N/A" />
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
  editSport: state.sportInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  updateSport: (data) => dispatch(updateSport(data)),
  uploadAFile: (data) => dispatch(uploadAFile(data)),
  getSportDetail: (data) => dispatch(getSportDetail(data)),
});
export default withRouter(connect(mapState, mapDispatch)(EditSport));
