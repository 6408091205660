import { GET_FAN_DETAIL, SET_INITIAL } from "store/types";

const initialState = {
  current: null,
};

const fanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAN_DETAIL:
      state.current = action.payload;
      return { ...state };

    case SET_INITIAL:
      state.current = null;
      return { ...state };

    default:
      return state;
  }
};

export default fanReducer;
