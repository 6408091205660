import React from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import FlagIcon from "@mui/icons-material/Flag";
// import { RankedDialogs, RatingDialogs } from "components/molecules";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import "./index.css";
import { Confirmation } from "components/molecules";
import { connect } from "react-redux";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { flagUnflagDeleteContent } from "store/actions";
import { roundOff, showAction } from "utils/helper";

const AthleteRankedTableList = (props) => {
  const {
    listData,
    flagUnflagDeleteContent,
    handleFilterSubmit,
    alPermissions,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];
  const [open, setOpen] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {},
  });

  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "flag") {
      obj = {
        ...obj,
        title: "Flag content",
        description: `Are you sure you want to flag ${row.content.caption} ?`,
        clickYes: () => {
          const apiData = {
            id: row.content._id,
            status: 2,
          };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete content",
        description: `Are you sure you want to delete ${row.content.caption} ?`,
        clickYes: () => {
          const apiData = {
            id: row.content._id,
            status: 3,
          };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Unflag content",
        description: `Are you sure you want to unflag ${row.content.caption} ?`,
        clickYes: () => {
          const apiData = {
            id: row.content._id,
            status: 1,
          };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        <figure>
          <FindInPageIcon />
        </figure>
        <h3>
          {listData?.result === undefined ? "Loading ..." : "No Data Found"}
        </h3>
      </div>
    );
  }
  return (
    <div className="table-wrapper athlete-rated">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Caption</TableCell>
              <TableCell>Uploader</TableCell>
              <TableCell>Fivestar Score</TableCell>
              {/* <TableCell>Rating</TableCell>
              <TableCell>Ranking</TableCell> */}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link to={`/video-feed/${row.content._id}`}>
                    <Tooltip
                      title={row?.content.caption ? row?.content.caption : "NA"}
                    >
                      <span>
                        {row.content.caption.length <= 10 &&
                          row.content.caption}
                        {row.content.caption.length > 10 &&
                          `${row.content.caption.slice(0, 10)} ...`}
                        {!row?.content.caption && "NA"}
                      </span>
                    </Tooltip>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    to={`/${
                      row.userData.userType === 1 ? "athletes" : "fans"
                    }/${row.userData.userId}`}
                  >
                    {row.userData.name}
                  </Link>
                </TableCell>
                <TableCell>{roundOff(row.content?.rating?.avgRating)}</TableCell>
                {/* <TableCell>
                  <RatingDialogs />
                </TableCell>
                <TableCell>
                  <RankedDialogs />
                </TableCell> */}
                {showAction(alPermissions, 1) && (
                  <TableCell>
                    <div className="action-wrap">
                      {row.content.adminStatus === 1 ? (
                        <Tooltip title="Flag content">
                          <IconButton
                            onClick={() =>
                              handleClickOpen({ type: "flag", row })
                            }
                          >
                            <FlagIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Unflag content">
                          <IconButton
                            onClick={() =>
                              handleClickOpen({ type: "unflag", row })
                            }
                          >
                            <FlagIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  flagUnflagDeleteContent: (data) => dispatch(flagUnflagDeleteContent(data)),
});
export default connect(mapState, mapDispatch)(AthleteRankedTableList);
