import * as React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { changeStaticContent, getAllStaticContent } from "store/actions";
import { Loader } from "components/atoms";
import { showSection, convertObjToStringParams } from "utils/helper";

const PrivacyPolicyEdit = (props) => {
  const {
    getAllStaticContent,
    alPermissions,
    showLoader,
    changeStaticContent,
  } = props;
  const history = useHistory();
  const { type } = useParams();
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 4))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { type: parseInt(type) };
    const params = convertObjToStringParams(apiData);
    getAllStaticContent({ params }).then((resp) => {
      if (resp) {
        setValue(resp.content);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      type: parseInt(type),
      content: value,
    };
    changeStaticContent(data).then((resp) => {
      if (resp) {
        setValue("");
        history.goBack();
      }
    });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="static-content-header">
              <div className="title-left">
                <ArrowBackIosNewIcon onClick={() => history.goBack()} />
                <h1 className="title">Edit</h1>
              </div>
              <div className="btn-group">
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </div>
            <div className="text-editor">
              <ReactQuill theme="snow" onChange={setValue} value={value} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  changeStaticContent: (data) => dispatch(changeStaticContent(data)),
  getAllStaticContent: (data) => dispatch(getAllStaticContent(data)),
});

export default connect(mapState, mapDispatch)(PrivacyPolicyEdit);
