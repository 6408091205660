import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

function createData(sNo, reportedby, reportedate, reportreasion) {
  return { sNo, reportedby, reportedate, reportreasion };
}

const rows = [
  createData(
    "1",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
  createData(
    "2",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
  createData(
    "3",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
  createData(
    "4",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
  createData(
    "5",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
  createData(
    "6",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
  createData(
    "7",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
  createData(
    "8",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
  createData(
    "9",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
  createData(
    "10",
    "Ashley James",
    "25th Jan 2020 at 12:30 PM",
    "This Post is an Un-appropriate Post"
  ),
];

const ReportDetailUserTableList = (props) => {
  return (
    <div className="reported-by-tab table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Report Id</TableCell>
              <TableCell>Reported By</TableCell>
              <TableCell>Report Date & Time</TableCell>
              <TableCell>Report Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row.sNo}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell>
                  <Link>{row.reportedby}</Link>
                </TableCell>
                <TableCell>{row.reportedate}</TableCell>
                <TableCell>{row.reportreasion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReportDetailUserTableList;
