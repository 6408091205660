import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Moment from "react-moment";
import Tooltip from "@mui/material/Tooltip";
import { showAction } from "utils/helper";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import IconButton from "@mui/material/IconButton";
import { connect } from "react-redux";
import { updateSingleReportStatus } from "store/actions";
import { Confirmation } from "components/molecules";

const VideoReportedTableList = (props) => {
  const {
    listData,
    reportPage,
    alPermissions,
    updateSingleReportStatus,
    handleFilterSubmit,
    fetchPageDetail,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];

  const [open, setOpen] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {},
  });

  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "mark-pending") {
      obj = {
        ...obj,
        title: "Mark as pending",
        description: `Are you sure you want to mark report-${row.reportId}  as pending ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            reportStatus: 1,
          };
          updateSingleReportStatus(apiData).then((res) => {
            if (res) {
              handleFilterSubmit();
              fetchPageDetail();
              setOpen(false);
            }
          });
        },
      };
    } else if (type === "mark-resolved") {
      obj = {
        ...obj,
        title: "Mark as resolved",
        description: `Are you sure you want to mark report-${row.reportId} as resolved ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            reportStatus: 2,
          };
          updateSingleReportStatus(apiData).then((res) => {
            if (res) {
              handleFilterSubmit();
              fetchPageDetail();
              setOpen(false);
            }
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  // const startVal =
  //   listData?.page && listData.page !== 1
  //     ? (listData?.page - 1) * filter.pageLimit
  //     : 0;

  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        <figure>
          <FindInPageIcon />
        </figure>
        <h3>
          {listData?.result === undefined ? "Loading ..." : "No Data Found"}
        </h3>
      </div>
    );
  }
  return (
    <div className="reported-by-tab table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Report Id</TableCell>
              <TableCell>Reported By</TableCell>
              <TableCell>Report Date & Time</TableCell>
              <TableCell>Report Reason</TableCell>
              <TableCell>Description</TableCell>
              <TableCell></TableCell>
              {reportPage && <TableCell>Report Status</TableCell>}
              {reportPage && showAction(alPermissions, 7) && (
                <TableCell>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {/* {(startVal + i + 1).toString().length === 1 && "00"}
                  {(startVal + i + 1).toString().length === 2 && "0"}
                  {startVal + i + 1} */}
                  {row.reportId}
                </TableCell>
                <TableCell>
                  <Link
                    to={`/${
                      row.userData.userType === 1 ? "fans" : "athletes"
                    }/${row.userData.userId}`}
                  >
                    {row.userData.name}
                  </Link>
                </TableCell>
                <TableCell>
                  <Moment format="Do MMM YYYY">{row.updatedAt}</Moment>
                  {" at "}
                  <Moment format="h:mm A">{row.updatedAt}</Moment>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.reason}>
                    <span>
                      {row.reason.length <= 20 && row.reason}
                      {row.reason.length > 20 &&
                        `${row.reason.slice(0, 20)} ...`}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {row.description ? (
                    <Tooltip title={row?.description}>
                      <span>
                        {row?.description.length <= 20 && row?.description}
                        {row?.description.length > 20 &&
                          `${row?.description.slice(0, 20)} ...`}
                      </span>
                    </Tooltip>
                  ) : (
                    "N/A"
                  )}
                </TableCell>
                <TableCell></TableCell>
                {reportPage && (
                  <TableCell
                    className={`status-${
                      row.reportStatus === 1 ? "block" : "unblock"
                    }`}
                  >
                    {row.reportStatus === 1 ? "Pending" : "Resolved"}
                  </TableCell>
                )}
                {reportPage && showAction(alPermissions, 7) && (
                  <TableCell>
                    <div className="action-wrap">
                      <Tooltip
                        title={`Mark ${
                          row.reportStatus === 1 ? "Resolved" : "Pending"
                        }`}
                      >
                        <IconButton
                          onClick={() =>
                            handleClickOpen({
                              type: `mark-${
                                row.reportStatus === 1 ? "resolved" : "pending"
                              }`,
                              row,
                            })
                          }
                        >
                          <AssignmentTurnedInIcon
                            color={row.reportStatus === 1 ? "success" : "error"}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});

const mapDispatch = (dispatch) => ({
  updateSingleReportStatus: (data) => dispatch(updateSingleReportStatus(data)),
});
export default connect(mapState, mapDispatch)(VideoReportedTableList);
