import authReducer from "./authReducer";
import toastReducer from "./toastReducer";
import loaderReducer from "./loaderReducer";
import fanReducer from "./fanReducer";
import athleteReducer from "./athleteReducer";
import sportReducer from "./sportReducer";
import sideMenuReducer from "./sideMenuReducer";
import videoFeedReducer from "./videoFeedReducer";
import subadminReducer from "./subadminReducer";
import notificationReducer from "./notificationReducer";
import supportReducer from "./supportReducer";
import earningReducer from "./earningReducer"

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  authInfo: authReducer,
  toastInfo: toastReducer,
  loaderInfo: loaderReducer,
  fanInfo: fanReducer,
  athleteInfo: athleteReducer,
  sportInfo: sportReducer,
  sideMenuInfo: sideMenuReducer,
  videoFeedInfo: videoFeedReducer,
  subadminInfo: subadminReducer,
  notificationInfo: notificationReducer,
  supportInfo: supportReducer,
  earningInfo: earningReducer,
});

export default rootReducer;
