import { TOGGLE_MENU, ACTIVATE_MENU_LEVEL2, SET_INITIAL } from "store/types";

const initialState = {
  userMgmt: false,
  menuLevel2: "",
};

const sideMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      Object.keys(state).forEach((k) => {
        if (k !== action.payload.key && !k.includes("menuLevel")) {
          state[k] = false;
        }
      });
      state[action.payload.key] = !state[action.payload.key];
      return { ...state };

    case ACTIVATE_MENU_LEVEL2:
      state.menuLevel2 = action.payload.key;
      return { ...state };

    case SET_INITIAL:
      state.userMgmt = false;
      state.menuLevel2 = "";
      return { ...state };

    default:
      return state;
  }
};

export default sideMenuReducer;
