import * as React from "react";
import { FanTaggedTableList, PaginationSection } from "components/molecules";
import TextField from "@mui/material/TextField";
import { FilterSubmit, FilterReset } from "components/atoms";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popover from "@mui/material/Popover";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

const FanTaggedTabSection = () => {
  const [datevalue, datesetValue] = React.useState([null, null]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="taged-wrap">
      <div className="search-filter-wrap">
        <div className="serach-wrap">
          <TextField
            label="Search"
            variant="outlined"
            placeholder="Search by name, email and mobile number"
          />
        </div>
        <div className="filter-btn">
          <Tooltip title="Filter">
            <IconButton
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              color="primary"
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <div className="filterPopup">
              <label className="form-label">Created date</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="From"
                  endText="To"
                  value={datevalue}
                  open={datePickerOpen}
                  onChange={(newValue) => {
                    datesetValue(newValue);
                    if (newValue[0] !== null && newValue[1] !== null) {
                      setDatePickerOpen(false);
                    }
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField
                        onClick={() => {
                          setDatePickerOpen(true);
                        }}
                        {...startProps}
                      />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField
                        onClick={() => {
                          setDatePickerOpen(true);
                        }}
                        {...endProps}
                      />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
              <div className="filter-text">
                <label className="form-label">Ranking</label>
                <TextField type="number" label="Select" variant="outlined" />
              </div>
              <div className="btn-wrap">
                <FilterReset name="Reset" />
                <FilterSubmit name="Apply Filter" />
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <FanTaggedTableList />
      <PaginationSection />
    </div>
  );
};

export default FanTaggedTabSection;
